export default function DiamondExplainer({ data }) {
  return (
    <div className="hidden border-t border-gray-200 md:block">
      <h3 className="my-4 border-none text-center text-xl font-semibold">
        The Four C's of Your Diamond
      </h3>
      <div className="grid gap-2 md:gap-4 lg:grid-cols-2">
        <div className="border border-gray-200 p-4">
          <h4 className="border-none">
            Diamond Size:{" "}
            <span className="text-cartier-red">{data.carats} Ct</span>
          </h4>
          <p className="mt-2 leading-snug">
            Carat is the unit of weight of a diamond. Carat is often confused
            with size, even though it measures weight. One carat equals 200
            milligrams or 0.2 grams. Remember that while the provided
            measurements are typical, every diamond is unique.
          </p>
        </div>
        <div className="border border-gray-200 p-4">
          <h4 className="border-none">
            Cut: <span className="text-cartier-red">{data.cut}</span>
          </h4>
          <p className="mt-2 leading-normal">
            The cut refers to the angles and proportions of a diamond. The form,
            finish, depth, width, and uniformity of the facets determine its
            beauty. The skill with which a diamond is cut determines how well it
            reflects and refracts light.
          </p>
        </div>
        <div className="border border-gray-200 p-4">
          <h4 className="border-none">
            Color: <span className="text-cartier-red">{data.color}</span>
          </h4>
          <p className="mt-2 leading-normal">
            Color is the natural color of a diamond and does not change over
            time. A diamond acts as a prism, dividing light into a spectrum of
            colors and reflecting light flashes called "fire." Colorless
            diamonds allow more light to pass through than colored ones.
          </p>
        </div>
        <div className="border border-gray-200 p-4">
          <h4 className="border-none">
            Clarity: <span className="text-cartier-red">{data.clarity}</span>
          </h4>
          <p className="mt-2 leading-normal">
            The clarity refers to the presence of impurities on and within a
            diamond. When a rough stone is extracted from deep beneath the
            earth, tiny traces of natural elements are almost always trapped
            inside and are called inclusions.
          </p>
        </div>
      </div>
    </div>
  );
}
