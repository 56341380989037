// packages
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { MdOutlineExpandMore, MdOutlineExpandLess } from "react-icons/md";

const DEFAULT_THEME = {
  container: "",
  expandButton: {
    all: "rounded-md",
    active: "bg-black text-white",
    inactive: "bg-theme-blue text-white",
  },
};

export default function Accordion({
  data,
  isFirstTabActive = false,
  theme = {},
}) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (isFirstTabActive) {
      setActiveTab(tabId(0));
    }
  }, []);

  const handleTabClick = (index) => {
    setActiveTab((prevTab) => (prevTab === tabId(index) ? null : tabId(index)));
  };

  const tabId = (index) => `accordion-body-${index}`;

  return (
    <div
      className={`${activeTheme.container}`}
      data-accordion="collapse"
      id="accordion"
    >
      {data.map((item, index) => (
        <React.Fragment key={index}>
          <p id={`accordion-heading-${index + 1}`}>
            <button
              aria-controls={tabId(index)}
              aria-expanded={activeTab === tabId(index)}
              className={`${activeTheme.expandButton.all} ${
                activeTab === tabId(index)
                  ? activeTheme.expandButton.active
                  : activeTheme.expandButton.inactive
              } flex w-full items-center justify-between gap-2 p-2`}
              data-accordion-target={`#${tabId(index)}`}
              onClick={() => handleTabClick(index)}
              type="button"
            >
              <span className="flex items-center text-sm font-bold">
                {item.label}
              </span>
              {activeTab === tabId(index) ? (
                <MdOutlineExpandLess className="shrink-0" size="16" />
              ) : (
                <MdOutlineExpandMore className="shrink-0" size="16" />
              )}
            </button>
          </p>
          <div
            aria-labelledby={`accordion-heading-${index + 1}`}
            className={`${activeTab === tabId(index) ? "" : "hidden"}`}
            id={tabId(index)}
          >
            {item.content}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}
