// packages
import { createContext, useContext, useEffect, useState } from "react";

// contexts
const ComparisonContext = createContext();
export const useComparison = () => useContext(ComparisonContext);

export const ComparisonProvider = ({ children, comparisonType }) => {
  const [comparisonData, setComparisonData] = useState([]);

  useEffect(() => {
    setComparisonData(loadComparisonData());
  }, [comparisonType]);

  const loadComparisonData = () => {
    const rawData = localStorage.getItem(`compare_${comparisonType}`);

    if (rawData) {
      return JSON.parse(rawData);
    } else {
      updateComparisonData([]);
      return [];
    }
  };

  const updateComparisonData = (newData) => {
    localStorage.setItem(`compare_${comparisonType}`, JSON.stringify(newData));
    setComparisonData(newData);
  };

  const toggleComparisonItem = (item) => {
    if (!item) {
      return;
    }

    const newData = [...comparisonData];
    const index = newData.findIndex((el) => el.id === item.id);

    if (index > -1) {
      newData.splice(index, 1);
    } else {
      newData.push(item);
    }

    updateComparisonData(newData);
  };

  return (
    <ComparisonContext.Provider
      value={{
        comparisonData,
        comparisonType,
        loadComparisonData,
        toggleComparisonItem,
        updateComparisonData,
      }}
    >
      {children}
    </ComparisonContext.Provider>
  );
};
