// assets
import IconGift from "../assets/icons/gift.svg";
import IconReturn from "../assets/icons/return.svg";
import IconShipping from "../assets/icons/shipping.svg";

export const promos = [
  {
    icon: IconShipping,
    text: "Complimentary Delivery",
  },
  {
    icon: IconGift,
    text: "Free Gift Wrapping",
  },
  {
    icon: IconReturn,
    text: "Easy Return Or Exchange",
  },
];
