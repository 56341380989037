// packages
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import axios from "axios";

// assets
import BannerDiamonds from "../assets/images/banners/diamonds.jpg";
import BannerShowcase from "../assets/images/banners/showcase.webp";

// classes
import Diamond from "../shared/classes/Diamond";
import Jewelry from "../shared/classes/Jewelry";

// components (shared)
import ItemGrid from "../shared/components/ItemGrid";

// components (template)
import Categories from "../components/Categories";
import Header from "../components/Header";
import HeroWithImage from "../components/HeroWithImage";
import ImageBanner from "../components/ImageBanner";
import StandardContainer from "../components/StandardContainer";

// data
import { categories } from "../data/categories";

export default function Home() {
  //////////////////////////////////////////////////////////////////////////////
  // data
  //////////////////////////////////////////////////////////////////////////////

  const [featuredItems, setFeaturedItems] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/companies/${process.env.REACT_APP_COMPANY_ID}/featured-items.json`,
        {
          headers: {
            Authorization: localStorage.token,
          },
        },
      )
      .then((response) => {
        const rawData = response.data;
        let data = {};
        data.diamonds = rawData.diamonds.map((item) => {
          return new Diamond(item, "/diamonds");
        });
        data.jewelry = rawData.jewelry.map((item) => {
          return new Jewelry(item, "/jewelry");
        });
        setFeaturedItems(data);
      })
      .catch((error) => {
        console.error("Error fetching featured items:", error);
      });
  }, []);

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <HeroWithImage
        title="Exceptional Perfection"
        subtitle="A legacy inspired by love and forged by craftsmanship"
        image={{
          cssStyles: { transform: "rotateY(180deg)" },
          src: BannerDiamonds,
        }}
        primaryButton={{
          href: "/diamonds",
          title: "Explore Our Diamonds",
        }}
        secondaryButton={{
          href: "/jewelry",
          title: "Discover Our Jewelry",
        }}
        stats={[
          { text: "Years in Business", href: "/about", value: "18+" },
          {
            text: "Steps To Order",
            href: "/process",
            value: "3",
          },
          {
            text: "Diamonds In Stock",
            href: "/diamonds",
            value: "10,000+",
          },
          { text: "Jewelry Styles", href: "/jewelry", value: "50+" },
        ]}
      />
      <div className="my-4 lg:mt-0">
        {featuredItems &&
          Object.keys(featuredItems).map((category, index) => {
            return (
              <div key={index}>
                <NavLink exact="exact" to={`/${category}`}>
                  <Header title={_.capitalize(category)} />
                  <ImageBanner image={categories[category].banner} />
                </NavLink>
                <StandardContainer
                  content={
                    <div className="mt-4">
                      <Categories
                        data={categories[category].categories}
                        imageCssClasses={categories[category].imageCssClasses}
                        path={categories[category].path}
                        param={categories[category].param}
                        title={categories[category].title}
                      />
                      <div className="my-4">
                        <h3 className="mb-8 mt-6 text-center text-xl font-semibold leading-snug">
                          <NavLink
                            className="border-effect"
                            to={`/${category}`}
                          >
                            {categories[category].subtitle}
                          </NavLink>
                        </h3>
                        {featuredItems && (
                          <ItemGrid
                            data={featuredItems[category]}
                            showSummary={true}
                          />
                        )}
                      </div>
                    </div>
                  }
                />
              </div>
            );
          })}
        <NavLink exact="exact" to="/showcase">
          <Header title="Showcase" />
          <ImageBanner image={BannerShowcase} />
        </NavLink>
      </div>
    </>
  );
}
