// packages
import _ from "lodash";
import { MdOutlineClose, MdOutlineForwardToInbox } from "react-icons/md";

// TODO: fix issue with modal not closing when clicking outside or pressing Esc
//  as currently implemented, it acts as a static modal instead of dynamic

const DEFAULT_THEME = {
  button: {
    primary:
      "bg-theme-blue text-white rounded-md hover:bg-black active:bg-black/50",
  },
  header: "bg-theme-blue text-white rounded-t-md",
  input:
    "border-gray-400 bg-gray-50 text-gray-800 rounded-md focus:border-theme-blue",
  label: "text-gray-800",
  overlay: "bg-black/40",
  window: "bg-white rounded-md",
};

export default function ModalInquiry({
  header,
  isModalOpen,
  setIsModalOpen,
  submitText = "Submit",
  theme = {},
}) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  // https://flowbite.com/docs/components/modal/#default-modal
  return (
    isModalOpen && (
      <div
        aria-hidden="true"
        className={`${activeTheme.overlay} fixed left-0 right-0 top-0 z-50 h-full max-h-full w-full overflow-y-auto overflow-x-hidden max-md:px-2 md:inset-0`}
        id="defaultModal"
        tabIndex="-1"
      >
        <div className="relative flex h-full max-h-full w-full items-center justify-center">
          <div className={`${activeTheme.window} relative w-full lg:w-2/3`}>
            {/* header */}
            <div
              className={`${activeTheme.header} flex items-center justify-between p-2 pb-4 md:p-4 md:pb-6`}
            >
              <h3 className="text-xl font-semibold">{header}</h3>
              <button
                className="inline-flex items-center justify-center bg-transparent hover:bg-white/10"
                onClick={() => setIsModalOpen(false)}
                type="button"
              >
                <MdOutlineClose size="20" className="shrink-0" />
              </button>
            </div>
            {/* content */}
            {/* TODO */}
            <form
              className="space-y-2 p-2 md:space-y-4 md:p-4"
              // onSubmit={handleSubmit}
              // ref={formRef}
            >
              <div className="flex flex-col gap-4 md:flex-row">
                <div className="space-y-2 md:w-1/2 md:space-y-4">
                  <div>
                    <label
                      className={`${activeTheme.label} block text-sm font-medium`}
                      htmlFor="iName"
                    >
                      Name
                    </label>
                    <input
                      className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
                      id="iName"
                      name="name"
                      placeholder="Your full name"
                      required="required"
                      type="text"
                    />
                  </div>
                  <div>
                    <label
                      className={`${activeTheme.label} block text-sm font-medium`}
                      htmlFor="iEmail"
                    >
                      Email
                    </label>
                    <input
                      className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
                      id="iEmail"
                      name="email"
                      placeholder="Your email address"
                      required="required"
                      type="email"
                    />
                  </div>
                </div>
                <div className="space-y-2 md:w-1/2 md:space-y-4">
                  <div>
                    <label
                      className={`${activeTheme.label} block text-sm font-medium`}
                      htmlFor="iPhone"
                    >
                      Phone
                    </label>
                    <input
                      className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
                      id="iPhone"
                      name="phone"
                      placeholder="Your phone number"
                      type="tel"
                    />
                  </div>
                  <div>
                    <label
                      className={`${activeTheme.label} block text-sm font-medium`}
                      htmlFor="iComments"
                    >
                      Comments
                    </label>
                    <input
                      className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
                      id="iComments"
                      name="comments"
                      placeholder="Your questions or comments"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <button
                className={`${activeTheme.button.primary} group flex w-full items-center justify-center gap-2 px-4 py-3 font-bold focus:ring-0 md:text-lg`}
                type="submit"
              >
                {submitText}
                <MdOutlineForwardToInbox
                  className="shrink-0 group-hover:scale-110"
                  size="20"
                />
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  );
}
