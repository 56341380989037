// assets
import CategorySketchAnklet from "../assets/images/jewelry/categories/w-anklet.png";
import CategorySketchBracelet from "../assets/images/jewelry/categories/w-bracelet.png";
import CategorySketchBrooch from "../assets/images/jewelry/categories/w-brooch.png";
import CategorySketchCharm from "../assets/images/jewelry/categories/w-charm.png";
import CategorySketchCufflink from "../assets/images/jewelry/categories/w-cufflink.png";
import CategorySketchEarring from "../assets/images/jewelry/categories/w-earring.png";
import CategorySketchNecklace from "../assets/images/jewelry/categories/w-necklace.png";
import CategorySketchPendant from "../assets/images/jewelry/categories/w-pendant.png";
import CategorySketchRing from "../assets/images/jewelry/categories/w-ring.png";
import CategorySketchStud from "../assets/images/jewelry/categories/w-stud.png";

// utils
import { formattedCurrency } from "../utils/currency";

export default class Jewelry {
  //////////////////////////////////////////////////////////////////////////////
  // public
  //////////////////////////////////////////////////////////////////////////////

  constructor(data, showPath = null) {
    this.data = data;
    this.route = showPath ? `${showPath}/${data.id}` : data.id;

    this.id = data.id;
    this.type = data.type;
    this.is_bookmarked = data.is_bookmarked;

    // primary
    this.stockNumber = data.item_no;
    this.title = data.description;
    this.summary = data.description_2;
    this.centerDescription = data.center_description;
    this.sideDescription = data.side_description;
    this.categoryData = Jewelry.CategoryData(data);
    this.category = data.category;
    this.subCategory = data.sub_category;
    this.model = data.model;
    this.style = data.style;
    this.design = data.design;
    this.gender = data.gender;
    this.size = data.size;
    this.madeIn = data.made_in;

    // metals
    this.metalType = data.metal_1_type;
    this.metalKarat = data.metal_1_karat;
    this.metalColor = data.metal_1_color;
    this.metalWeight = this.#formatMetalWeight();

    // stones
    this.stoneDescription = data.stone_1_description;
    this.stoneCarats = data.stone_1_carats;
    this.stoneShape = data.stone_1_shape_code;
    this.stoneClarity = data.stone_1_clarity_code;
    this.stoneColor = data.stone_1_color_code;

    // price
    this.price = formattedCurrency(data.price_total_1, 0);

    // assets
    this.videoUrl = data.video_url;
    this.webUrl = data.web_url;
    this.images = {
      main: { alt: this.title, src: this.#imagePath() },
      thumb: { alt: this.title, src: this.#imagePath(true) },

      // TODO
      // gallery: [
      //   { alt: this.title, src: data.image_1_filename },
      //   { alt: this.title, src: data.image_2_filename },
      //   { alt: this.title, src: data.image_3_filename },
      //   { alt: this.title, src: data.image_4_filename },
      // ],
    };

    this.overview = {
      stockNumber: this.stockNumber,
      category: this.category,
      subCategory: this.subCategory,
      model: this.model,
      style: this.style,
      design: this.design,
      size: this.size,
      metalType: this.metalType,
      metalKarat: this.metalKarat,
      metalColor: this.metalColor,
      metalWeight: this.metalWeight,
      madeIn: this.madeIn,
    };

    this.categoryDetails = (
      <div className="flex flex-col items-center justify-start gap-4 md:flex-row">
        {this.categoryData && (
          <img
            alt={this.title}
            className="h-6 object-cover"
            src={this.categoryData.icon}
          />
        )}
        {this.data.category}
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  // private
  //////////////////////////////////////////////////////////////////////////////

  #imagePath(thumb = false) {
    if (this.data.images && this.data.images[0]) {
      return this.data.images[0].url;
    }

    // TODO
    // if (this.data.image_1_filename) {
    //   return `${process.env.REACT_APP_CLOUDFRONT_URL}/stones/images/${this.data.image_1_filename}`;
    // }

    if (this.categoryData) {
      if (thumb) {
        return this.categoryData.photos.thumb;
      } else {
        return this.categoryData.photos.main;
      }
    }
  }

  #formatMetalWeight() {
    let metalWeight = "";

    if (this.data.metal_1_weight) {
      let metalWeightAsFloat = parseFloat(this.data.metal_1_weight);

      if (typeof metalWeightAsFloat === "number") {
        metalWeight = metalWeightAsFloat.toFixed(2);
      }
    }

    return metalWeight;
  }

  //////////////////////////////////////////////////////////////////////////////
  // static
  //////////////////////////////////////////////////////////////////////////////

  static CategoryData(item) {
    return Jewelry.Categories().find((data) => data.value === item.category);
  }

  // definitions
  // TODO: replace with search properties from the API

  static Categories() {
    return [
      {
        label: "Rings",
        value: "RING",
        icon: CategorySketchRing,
        photos: { main: CategorySketchRing, thumb: CategorySketchRing },
      },
      {
        label: "Bands",
        value: "BAND",
        icon: CategorySketchAnklet,
        photos: { main: CategorySketchAnklet, thumb: CategorySketchAnklet },
      },
      {
        label: "Bracelets",
        value: "BRACELET",
        icon: CategorySketchBracelet,
        photos: {
          main: CategorySketchBracelet,
          thumb: CategorySketchBracelet,
        },
      },
      {
        label: "Semi-Mounts",
        value: "SEMI-MOUNT",
        icon: CategorySketchStud,
        photos: { main: CategorySketchStud, thumb: CategorySketchStud },
      },
      {
        label: "Earrings",
        value: "EARRING",
        icon: CategorySketchEarring,
        photos: { main: CategorySketchEarring, thumb: CategorySketchEarring },
      },
      {
        label: "Necklaces",
        value: "NECKLACE",
        icon: CategorySketchNecklace,
        photos: {
          main: CategorySketchNecklace,
          thumb: CategorySketchNecklace,
        },
      },
      {
        label: "Pendants",
        value: "PENDANT",
        icon: CategorySketchPendant,
        photos: { main: CategorySketchPendant, thumb: CategorySketchPendant },
      },
      // {
      //   label: "Studs",
      //   value: "Stud",
      //   icon: CategorySketchStud,
      //   photos: { main: CategorySketchStud, thumb: CategorySketchStud },
      // },
      // {
      //   label: "Anklets",
      //   value: "Anklet",
      //   icon: CategorySketchAnklet,
      //   photos: { main: CategorySketchAnklet, thumb: CategorySketchAnklet },
      // },
      // {
      //   label: "Brooches",
      //   value: "Brooch",
      //   icon: CategorySketchBrooch,
      //   photos: { main: CategorySketchBrooch, thumb: CategorySketchBrooch },
      // },
      // {
      //   label: "Charms",
      //   value: "Charm",
      //   icon: CategorySketchCharm,
      //   photos: { main: CategorySketchCharm, thumb: CategorySketchCharm },
      // },
      // {
      //   label: "Cufflinks",
      //   value: "Cufflink",
      //   icon: CategorySketchCufflink,
      //   photos: {
      //     main: CategorySketchCufflink,
      //     thumb: CategorySketchCufflink,
      //   },
      // },
    ];
  }

  static MetalKarats() {
    return {
      "24K": "24",
      "22K": "22",
      "21K": "21",
      "20K": "20",
      "18K": "18",
      "14K": "14",
      "10K": "10",
      "9K": "9",
    };
  }

  static Sizes() {
    return {
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      10: "10",
      11: "11",
      12: "12",
      13: "13",
    };
  }
}
