// components (shared)
import JewelryUI from "../shared/components/JewelryUI";

// components (template)
// import Header from "../components/Header";
// import ImageBanner from "../components/ImageBanner";
import StandardContainer from "../components/StandardContainer";

// data
// import { categories } from "../data/categories";

export default function Jewelries() {
  return (
    <>
      {/* <Header title="Jewelry Search" /> */}
      {/* <ImageBanner image={categories.jewelry.banner} /> */}
      <div className="my-2 font-cartier-sans font-semibold text-black md:my-4">
        <StandardContainer content={<JewelryUI />} />
      </div>
    </>
  );
}
