// packages
import _ from "lodash";
import { PiArrowSquareRightLight, PiXSquareLight } from "react-icons/pi";

// TODO: fix issue with modal not closing when clicking outside or pressing Esc
//  as currently implemented, it acts as a static modal instead of dynamic

const DEFAULT_THEME = {
  button: {
    primary: "bg-t-red text-t-skin hover:bg-t-gray hover:text-t-sky",
  },
  header: "bg-t-black text-white",
  input: "border-black/5 bg-gray-50 focus:border-t-beige",
  label: "text-gray-800",
  overlay: "bg-t-black/90",
  window: "bg-white",
};

export default function ModalInquiry({
  header,
  isModalOpen,
  setIsModalOpen,
  submitText = "Submit",
  theme = {},
}) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  // https://flowbite.com/docs/components/modal/#default-modal
  return (
    isModalOpen && (
      <div
        aria-hidden="true"
        className={`${activeTheme.overlay} fixed left-0 right-0 top-0 z-50 h-full max-h-full w-full overflow-y-auto overflow-x-hidden max-md:px-2 md:inset-0`}
        tabIndex="-1"
      >
        <div className="relative flex h-full max-h-full w-full items-center justify-center">
          <div className={`${activeTheme.window} relative w-full lg:w-5/12`}>
            {/* header */}
            <div
              className={`${activeTheme.header} flex items-center justify-between p-4`}
            >
              <h3 className="s-h4">{header}</h3>
              <button
                className="inline-flex items-center justify-center bg-transparent text-t-beige hover:text-t-skin"
                onClick={() => setIsModalOpen(false)}
                type="button"
              >
                <PiXSquareLight
                  size={32}
                  className="shrink-0"
                />
              </button>
            </div>
            {/* content */}
            {/* TODO */}
            <form
              className="space-y-2 p-2 md:space-y-4 md:p-4 xl:p-8"
              // onSubmit={handleSubmit}
              // ref={formRef}
            >
              <div className="w-full space-y-2 md:space-y-4 xl:space-y-6">
                <div>
                  {/* <label
                      className={`${activeTheme.label} block text-sm font-medium`}
                      htmlFor="iName"
                    >
                      Name
                    </label> */}
                  <input
                    className={`${activeTheme.input} w-full border p-2 focus:ring-0`}
                    id="iName"
                    name="name"
                    placeholder="Your full name"
                    required="required"
                    type="text"
                  />
                </div>
                <div>
                  {/* <label
                      className={`${activeTheme.label} block text-sm font-medium`}
                      htmlFor="iEmail"
                    >
                      Email
                    </label> */}
                  <input
                    className={`${activeTheme.input} w-full border p-2 focus:ring-0`}
                    id="iEmail"
                    name="email"
                    placeholder="Your email address"
                    required="required"
                    type="email"
                  />
                </div>
                <div>
                  {/* <label
                      className={`${activeTheme.label} block text-sm font-medium`}
                      htmlFor="iPhone"
                    >
                      Phone
                    </label> */}
                  <input
                    className={`${activeTheme.input} w-full border p-2 focus:ring-0`}
                    id="iPhone"
                    name="phone"
                    placeholder="Your phone number"
                    type="tel"
                  />
                </div>
                <div>
                  {/* <label
                      className={`${activeTheme.label} block text-sm font-medium`}
                      htmlFor="iComments"
                    >
                      Comments
                    </label> */}
                  <input
                    className={`${activeTheme.input} w-full border p-2 focus:ring-0`}
                    id="iComments"
                    name="comments"
                    placeholder="Your questions or comments"
                    type="text"
                  />
                </div>
              </div>
              <button
                className={`${activeTheme.button.primary} s-text group mt-6 flex w-full items-center justify-center gap-2 px-4 py-3 font-medium focus:ring-0`}
                type="submit"
              >
                {submitText}
                <PiArrowSquareRightLight
                  className="shrink-0"
                  size={20}
                />
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  );
}
