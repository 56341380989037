// packages
import { useEffect } from "react";

export default function AddToAny() {
  // https://www.addtoany.com/buttons/for/website

  const addToAnyCss = `
    .a2a_svg, .a2a_count {
    border-radius: 0 !important;
    }
  `;

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://static.addtoany.com/menu/page.js";
    document.body.appendChild(script);

    script.onload = () => {
      const configScript = document.createElement("script");
      configScript.textContent = "var a2a_config = a2a_config || {};";
      configScript.textContent = "a2a_config.onclick = 1;";
      configScript.textContent = "a2a_config.icon_color = '#D50032';";
      // configScript.textContent = "a2a_config.color_bg = '#FFFFFF';";
      // configScript.textContent = "a2a_config.color_main = '#D7E5ED';";
      // configScript.textContent = "a2a_config.color_border = '#AECADB';";
      configScript.textContent = "a2a_config.color_link_text = '#D50032';";
      // configScript.textContent = "a2a_config.color_link_text_hover = '#333333';";

      document.body.appendChild(configScript);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <style>{addToAnyCss}</style>;
}
