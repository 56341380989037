export function formattedCurrency(amount, fractionDigits = 2) {
  let amountInt = parseInt(amount, 10);

  if (isNaN(amountInt)) {
    return "";
  }

  const userLocale = navigator.language;

  const currencyFormatter = new Intl.NumberFormat(userLocale, {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: fractionDigits,
  });

  return currencyFormatter.format(amountInt);
}
