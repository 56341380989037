// packages
import axios from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

// assets
import BannerCatalog from "../../assets/images/banners/catalog.avif";

// classes
import Diamond from "../../shared/classes/Diamond";
import Jewelry from "../../shared/classes/Jewelry";

// components (shared)
import ItemGrid from "../../shared/components/ItemGrid";

// components (template)
import Categories from "../../components/Categories";
import Header from "../../components/Header";
import ImageBanner from "../../components/ImageBanner";
import Promos from "../../components/Promos";
import Slider from "../../components/Slider";
import StandardContainer from "../../components/StandardContainer";
// data
import { categories } from "../../data/categories";

export default function HomeA() {
  const [featuredItems, setFeaturedItems] = useState([]);

  useEffect(() => {
    const fetchFeaturedItems = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/companies/${process.env.REACT_APP_COMPANY_ID}/featured-items.json`,
          {
            headers: {
              Authorization: localStorage.token,
            },
          },
        );
        const rawData = response.data;
        let data = {};
        data.diamonds = rawData.diamonds.map(
          (item) => new Diamond(item, "/diamonds"),
        );
        data.jewelry = rawData.jewelry.map(
          (item) => new Jewelry(item, "/jewelry"),
        );
        setFeaturedItems(data);
      } catch (error) {
        console.error("Error fetching featured items:", error);
      }
    };

    fetchFeaturedItems();
  }, []);

  const categorySections =
    featuredItems &&
    Object.keys(featuredItems).map((category, idx) => {
      return (
        <div key={idx}>
          <StandardContainer
            content={
              <>
                <NavLink
                  className="text-t-gray"
                  exact="exact"
                  to={`/${category}`}
                >
                  <Header
                    title={_.capitalize(category)}
                    topBorder={true}
                    bottomBorder={false}
                  />
                  <div className="mb-4 xl:mb-8">
                    <ImageBanner image={categories[category].banner} />
                  </div>
                </NavLink>
                <div className="flex flex-col gap-4 md:gap-8">
                  <Categories
                    data={categories[category].categories}
                    imageCssClasses={categories[category].imageCssClasses}
                    path={categories[category].path}
                    param={categories[category].param}
                    // title={categories[category].title}
                  />
                  <ItemGrid
                    data={featuredItems[category]}
                    limit={8}
                  />
                </div>
              </>
            }
          />
        </div>
      );
    });

  const catalogSection = (
    <StandardContainer
      content={
        <>
          <NavLink
            className="text-t-gray"
            exact="exact"
            to="/catalog"
          >
            <Header
              title="Catalog"
              topBorder={true}
              bottomBorder={false}
            />
            <ImageBanner image={BannerCatalog} />
          </NavLink>
        </>
      }
    />
  );

  return (
    <>
      <Slider />
      {categorySections}
      {catalogSection}
      <Promos />
    </>
  );
}
