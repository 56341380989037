// packages
import { useEffect, useState } from "react";
import ReactSlider from "react-slider";
import _ from "lodash";

const DEFAULT_THEME = {
  input: "border-black rounded-md focus:border-theme-blue",
  mark: "bg-theme-blue/20",
  thumb: "bg-theme-blue text-white rounded-full focus:ring-theme-blue/40",
  title: "text-theme-blue",
  track: {
    active: "bg-theme-blue",
    inactive: "bg-theme-red",
  },
};

// https://zillow.github.io/react-slider/

export default function RangeNumeric({
  callback,
  limitMax,
  limitMin,
  markMax,
  markMin,
  marks,
  positionCalculator,
  step,
  theme = {},
  title,
  valMax,
  valMin,
  valueCalculator,
  valueFormatter,
}) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  const [displayedValueMax, setDisplayedValueMax] = useState("");
  const [displayedValueMin, setDisplayedValueMin] = useState("");
  const [thumbMax, setThumbMax] = useState(markMax || limitMax);
  const [thumbMin, setThumbMin] = useState(markMin || limitMin);
  const [valueMax, setValueMax] = useState(valMax || limitMax);
  const [valueMin, setValueMin] = useState(valMin || limitMin);
  const [valueMaxOld, setValueMaxOld] = useState(valueMin);
  const [valueMinOld, setValueMinOld] = useState(valueMax);

  useEffect(() => {
    setThumbMax(calculatePosition(valueMax));
    setThumbMin(calculatePosition(valueMin));
  }, [limitMax, limitMin]);

  useEffect(() => {
    setDisplayedValueMax(formatValue(valueMax));
    setDisplayedValueMin(formatValue(valueMin));
  }, [valueMax, valueMin]);

  const calculateValue = (number) => {
    return valueCalculator ? valueCalculator(number) : number;
  };

  const calculatePosition = (value) => {
    return positionCalculator ? positionCalculator(value) : value;
  };

  const formatValue = (value) => {
    return valueFormatter ? valueFormatter(value) : value;
  };

  const handleInputBlur = (_e) => {
    const numericStringMax = _.replace(displayedValueMax, /[^0-9.]+/g, "");
    const numericStringMin = _.replace(displayedValueMin, /[^0-9.]+/g, "");

    const newValueMax = numericStringMax
      ? parseFloat(numericStringMax)
      : limitMax;

    const newValueMin = numericStringMin
      ? parseFloat(numericStringMin)
      : limitMin;

    setValueMax(newValueMax);
    setValueMin(newValueMin);

    if (valueMax !== newValueMax || valueMin !== newValueMin) {
      callback(newValueMin, newValueMax);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const numericString = _.replace(value, /[^0-9.]+/g, "");

    if (name === "maxInput") {
      let numericValue = numericString ? parseFloat(numericString) : limitMax;
      setDisplayedValueMax(formatValue(numericString));
      setThumbMax(calculatePosition(numericValue));
    } else if (name === "minInput") {
      let numericValue = numericString ? parseFloat(numericString) : limitMin;
      setDisplayedValueMin(formatValue(numericString));
      setThumbMin(calculatePosition(numericValue));
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.code === "Enter") {
      handleInputBlur();
    }
  };

  const handleSliderAfterChange = (_values, _thumbIndex) => {
    if (valueMax !== valueMaxOld || valueMin !== valueMinOld) {
      callback(valueMin, valueMax);
    }
  };

  const handleSliderBeforeChange = (_values, _thumbIndex) => {
    setValueMaxOld(valueMax);
    setValueMinOld(valueMin);
  };

  const handleSliderChange = (values, _thumbIndex) => {
    setThumbMin(values[0]);
    setThumbMax(values[1]);
    setValueMin(calculateValue(values[0]));
    setValueMax(calculateValue(values[1]));
  };

  const renderMark = (props) => {
    return <div {...props} className={`${activeTheme.mark} h-2 w-1`} />;
  };

  const renderThumb = (props, _state) => {
    return (
      <div
        {...props}
        className={`${activeTheme.thumb} h-3 w-3 cursor-grab font-bold focus:ring-2 focus:ring-offset-2`}
      />
    );
  };

  const renderTrack = (props, _state) => {
    return (
      <div
        {...props}
        className={`${
          props.key === "track-2"
            ? `${activeTheme.track.inactive} h-0.5`
            : props.key === "track-1"
              ? `${activeTheme.track.active} h-1`
              : `${activeTheme.track.inactive} h-0.5`
        }`}
      />
    );
  };

  return (
    <>
      {thumbMax !== undefined && thumbMin !== undefined ? (
        <div>
          <p className={`${activeTheme.title} text-sm font-bold`}>{title}</p>
          <div className="mt-2 flex gap-2">
            <input
              className={`${activeTheme.input} w-1/2 text-sm focus:ring-0`}
              name="minInput"
              onBlur={handleInputBlur}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              type="text"
              value={displayedValueMin}
            />
            <input
              className={`${activeTheme.input} w-1/2 text-sm focus:ring-0`}
              name="maxInput"
              onBlur={handleInputBlur}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              type="text"
              value={displayedValueMax}
            />
          </div>
          <ReactSlider
            className="mt-3 flex items-center"
            marks={marks}
            max={markMax || limitMax}
            min={markMin || limitMin}
            onAfterChange={handleSliderAfterChange}
            onBeforeChange={handleSliderBeforeChange}
            onChange={handleSliderChange}
            renderMark={renderMark}
            renderThumb={renderThumb}
            renderTrack={renderTrack}
            step={step}
            value={[thumbMin, thumbMax]}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
