export const contacts = [
  {
    title: "Call",
    subtitle: "Business hours",
    content: [
      "Weekdays: 9 a.m. - 6 p.m.",
      "Saturday: closed",
      "Sunday: closed",
    ],
    link: {
      href: "tel:+12136244400",
      text: "(213) 624-4400",
    },
  },
  {
    title: "Los Angeles",
    subtitle: "Visit our flagship store",
    link: {
      href: "https://maps.app.goo.gl/We2jGVNfv57A48oK6",
      text: "550 S Hill St<br />Suite #1101<br />Los Angeles, CA 90013",
    },
  },
  {
    title: "New York",
    subtitle: "Visit our NY location",
    link: {
      href: "https://maps.app.goo.gl/XhDu6QkWvtbR4g3n8",
      text: "580 5th Ave<br />Suite #2600<br />New York, NY 10036",
    },
  },
  {
    title: "Email",
    subtitle: "Reach our customer service",
    link: {
      href: "mailto:sales@ninacci.com",
      text: "Send a message",
    },
  },
  {
    title: "Chat",
    subtitle: "Connect with an ambassador",
    link: {
      href: "https://wa.me/12136244400",
      text: "Start chatting",
    },
  },
  {
    title: "FAQ",
    subtitle: "Find answers to commonly asked questions",
    link: {
      href: "/faq",
      text: "Explore FAQ",
    },
  },
];
