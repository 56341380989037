// packages
import { MdOutlineDelete } from "react-icons/md";
import _ from "lodash";

// components
import Alert from "../components/Alert";
import Table from "../components/Table";

// contexts
import { useComparison } from "../contexts/ComparisonContext";

const DEFAULT_THEME = {
  button: {
    primary:
      "bg-theme-blue text-white rounded-md hover:bg-black hover:text-white",
  },
};

export default function Compare({ columns, theme }) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  const { comparisonData, comparisonType, updateComparisonData } =
    useComparison();

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  return (
    <>
      {comparisonData.length > 0 ? (
        <>
          <div className="mt-2 flex justify-end md:mt-4">
            <button
              key="clearButton"
              onClick={() => updateComparisonData([])}
              title="Remove all items from comparison"
              className={`${activeTheme.button.primary} flex w-fit items-center px-2 py-1.5 text-sm`}
            >
              <MdOutlineDelete className="mr-1 shrink-0" size="16" />
              Remove All
            </button>
          </div>
          <Table data={comparisonData} columns={columns} />
        </>
      ) : (
        <Alert
          content={`Nothing to compare yet, select some ${comparisonType} for comparison.`}
          type="warning"
          visible={true}
        />
      )}
    </>
  );
}
