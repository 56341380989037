// packages
import _ from "lodash";
import { useState } from "react";

const DEFAULT_THEME = {
  option: {
    all: "",
    active: "border-black/20",
    hover: "hover:border-t-beige",
    inactive: "",
  },
  title: "",
};

export default function SelectImages({
  data,
  name,
  onChange,
  selectedValues,
  theme,
  title,
}) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  const [values, setValues] = useState(selectedValues);

  const handleClick = (value) => {
    let newValues = [...selectedValues];
    const index = newValues.indexOf(value);

    if (index !== -1) {
      newValues.splice(index, 1);
    } else {
      newValues.push(value);
    }

    setValues(newValues);
    onChange({ target: { name, value: newValues } });
  };

  return (
    <div>
      <p className={`${activeTheme.title} text-sm`}>{title}</p>
      <div className="mt-2 grid grid-cols-2 gap-1 md:grid-cols-5">
        {data.map((item, idx) => (
          <button
            className={`${activeTheme.option.all} ${activeTheme.option.hover} ${
              values.includes(item.value)
                ? activeTheme.option.active
                : activeTheme.option.inactive
            } flex flex-col items-center gap-1 border border-transparent p-2 md:gap-2`}
            key={idx}
            name={name}
            onClick={() => handleClick(item.value)}
          >
            <img
              className="h-12 object-contain"
              alt={item.label}
              src={item.photos.main}
            />
            <p className="text-xs">{item.label}</p>
          </button>
        ))}
      </div>
    </div>
  );
}
