import axios from "axios";

import { isTokenValid } from "./auth";

export async function fetchProfile() {
  if (!isTokenValid()) {
    console.warn("Token invalid");
    return;
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profile`,
      {
        headers: {
          Authorization: localStorage.token,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching profile:", error);
    throw error;
  }
}

export function isClientUser(role) {
  return !isCompanyUser(role);
}

export function isCompanyUser(role) {
  return (
    role === "owner" ||
    role === "manager" ||
    role === "employee" ||
    role === "salesperson"
  );
}

export function isCompanyOwner(role) {
  return role === "owner";
}

export function isSuperAdminUser(role) {
  return role === "superadmin";
}
