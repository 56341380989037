// packages
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { MdOutlineClose, MdOutlineSend } from "react-icons/md";

// components
import Alert from "../shared/components/Alert";
import Header from "../components/Header";
import LoadingIndicator from "../shared/components/LoadingIndicator";
import StandardContainer from "../components/StandardContainer";

// TODO: refactor to use the existing Modal component

const DEFAULT_THEME = {
  // own settings
  button: {
    primary:
      "bg-theme-blue text-white rounded-md hover:bg-black active:bg-black/50",
  },
  container: "bg-theme-blue",
  input:
    "border-gray-400 bg-gray-50 text-gray-800 rounded-md focus:border-theme-blue",
  link: "text-theme-blue hover:text-gray-800 hover:underline",

  tabTheme: {},
};

export default function Profile({ theme = {} }) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  // data
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  // states
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [updateAttribute, setUpdateAttribute] = useState("");
  const [updateSuccessful, setUpdateSuccessful] = useState(false);

  useEffect(() => {
    const profile = JSON.parse(localStorage.profile);
    setUser(profile);
  }, []);

  const fetchUser = () => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/profile`, {
        headers: {
          Authorization: localStorage.token,
        },
      })
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        // alert("Error fetching profile");
        console.error("Error fetching profile:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openModal = (type) => {
    setUpdateAttribute(type);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleUpdate = async (e, attribute, data, successMessage) => {
    e.preventDefault();
    setAlertVisible(false);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/${attribute}`,
        data,
        {
          headers: {
            Authorization: localStorage.token,
          },
        },
      );

      fetchUser();
      setAlertMessage(successMessage);
      setUpdateSuccessful(true);
    } catch (error) {
      console.error(`Error updating ${attribute}:`, error);
      setAlertMessage(`Error updating your details, please try again.`);
      setUpdateSuccessful(false);
    } finally {
      closeModal();
      setAlertVisible(true);
    }
  };

  const form = () => {
    const handleSubmit = async (e) => {
      const formData = new FormData(e.target);
      const data = {};

      formData.forEach((value, key) => {
        data[key] = value;
      });

      if (
        updateAttribute === "password" &&
        data.password !== data.password_confirmation
      ) {
        e.preventDefault();
        setAlertMessage("New password and confirmation did not match.");
        setAlertVisible(true);
        setModalVisible(false);
        setUpdateSuccessful(false);
        return;
      }

      handleUpdate(
        e,
        `profile/${updateAttribute}`,
        { user: data },
        `Your ${updateAttribute} has been updated.`,
      );
    };

    return (
      <div
        aria-hidden="true"
        className="fixed left-0 right-0 top-0 z-50 h-[calc(100%-1rem)] max-h-full w-full overflow-y-auto overflow-x-hidden bg-black/50 p-4 md:inset-0"
        id="defaultModal"
        tabIndex="-1"
      >
        <div className="relative flex h-full max-h-full w-full items-center justify-center">
          <div className="relative w-full rounded-md bg-white shadow lg:w-1/2">
            <form onSubmit={handleSubmit}>
              {/* header */}
              <div
                className={`${activeTheme.container} flex items-center justify-between rounded-t border-b p-4 text-white`}
              >
                <h3 className="text-xl font-semibold">
                  {updateAttribute === "email" && "Update Email"}
                  {updateAttribute === "password" && "Update Password"}
                  {updateAttribute === "full-name" && "Update Name"}
                </h3>
                <button
                  className="inline-flex h-8 w-8 items-center justify-center rounded-md bg-transparent hover:bg-white/10"
                  onClick={closeModal}
                  type="button"
                >
                  <MdOutlineClose size="24" className="shrink-0" />
                </button>
              </div>
              {/* body */}
              <div className="space-y-4 p-4">
                <label
                  className="mb-2 block text-sm font-medium text-gray-900"
                  htmlFor="currentPassword"
                >
                  {updateAttribute === "password" ? "Current " : ""}
                  Password
                </label>
                <input
                  className={`${activeTheme.input} block w-full rounded-md border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900`}
                  id="currentPassword"
                  minLength={8}
                  name="current_password"
                  type="password"
                  required
                />
                <label
                  className="mb-2 block text-sm font-medium text-gray-900"
                  htmlFor={updateAttribute}
                >
                  {updateAttribute === "email"
                    ? "New Email"
                    : updateAttribute === "password"
                      ? "New Password"
                      : "Your Name"}
                </label>
                <input
                  className={`${activeTheme.input} block w-full rounded-md border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900`}
                  id={updateAttribute}
                  minLength={updateAttribute === "password" ? 8 : ""}
                  name={
                    updateAttribute === "email"
                      ? "email"
                      : updateAttribute === "password"
                        ? "password"
                        : "full_name"
                  }
                  placeholder={
                    updateAttribute === "email"
                      ? "example@gmail.com"
                      : updateAttribute === "password"
                        ? "••••••••"
                        : "Full Name"
                  }
                  type={
                    updateAttribute === "email"
                      ? "email"
                      : updateAttribute === "password"
                        ? "password"
                        : "text"
                  }
                  required
                />
                {updateAttribute === "password" && (
                  <>
                    <label
                      className="mb-2 block text-sm font-medium text-gray-900"
                      htmlFor="passwordConfirmation"
                    >
                      Confirm Password
                    </label>
                    <input
                      className={`${activeTheme.input} block w-full rounded-md border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900`}
                      id="passwordConfirmation"
                      minLength={8}
                      name="password_confirmation"
                      placeholder="minimum of 8 characters"
                      type="password"
                      required
                    />
                  </>
                )}
              </div>
              {/* footer */}
              <div className="flex items-center justify-end space-x-2 border-gray-200 px-4 pb-2">
                <button
                  className={`${activeTheme.button.primary} group flex items-center rounded-md px-3 py-2 text-white hover:bg-black`}
                  type="submit"
                >
                  Update
                  <MdOutlineSend
                    className="ml-2 shrink-0 group-hover:scale-[1.2]"
                    size="16"
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const content = loading ? (
    <LoadingIndicator message="Loading profile" />
  ) : (
    <>
      {/* alert */}
      {alertVisible && (
        <Alert
          content={alertMessage}
          type={updateSuccessful ? "success" : "error"}
          visible={alertVisible}
        />
      )}
      {/* modal */}
      {modalVisible && form()}
      {/* main */}
      <div className="mb-2 mt-2 gap-x-2 rounded-md bg-gray-50 p-4 md:grid md:grid-cols-2">
        <div className="space-y-2 md:space-y-4">
          <h2 className="text-2xl font-semibold text-black">
            {user.full_name}
          </h2>
          <p className="text-base font-semibold text-gray-600">{user.email}</p>
          <h3 className="text-xl font-bold text-gray-600">{user.company}</h3>
        </div>
        <ul className="rounded-md bg-gray-50 py-2 text-sm max-md:mt-2">
          <li className="flex justify-between border-b-2 border-white px-4 py-2">
            <span className="font-semibold">Role</span>
            {user.role}
          </li>
          <li className="flex justify-between border-b-2 border-white px-4 py-2">
            <span className="font-semibold">Bookmarks</span>
            <NavLink
              className={`${activeTheme.link}`}
              exact="exact"
              to="/bookmarks"
            >
              View
            </NavLink>
          </li>
          <li className="flex justify-between border-b-2 border-white px-4 py-2">
            <span className="font-semibold">Name</span>
            <a
              className={`${activeTheme.link}`}
              href="#"
              onClick={() => openModal("full-name")}
            >
              Update
            </a>
          </li>
          <li className="flex justify-between border-b-2 border-white px-4 py-2">
            <span className="font-semibold">Email</span>
            <a
              className={`${activeTheme.link}`}
              href="#"
              onClick={() => openModal("email")}
            >
              Update
            </a>
          </li>
          <li className="flex justify-between px-4 py-2">
            <span className="font-semibold">Password</span>
            <a
              className={`${activeTheme.link}`}
              href="#"
              onClick={() => openModal("password")}
            >
              Update
            </a>
          </li>
        </ul>
      </div>
    </>
  );

  return (
    <>
      <Header title="Profile" />
      <StandardContainer content={content} />
    </>
  );
}
