// packages
import { useState } from "react";
import _ from "lodash";

const DEFAULT_THEME = {
  option: {
    all: "rounded-md",
    active: "border-theme-blue bg-gray-50",
    hover: "hover:bg-gray-100",
    inactive: "border-white",
  },
  title: "text-theme-blue",
};

export default function SelectImages({
  data,
  name,
  onChange,
  selectedValues,
  theme,
  title,
}) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  const [values, setValues] = useState(selectedValues);

  const handleClick = (value) => {
    let newValues = [...selectedValues];
    const index = newValues.indexOf(value);

    if (index !== -1) {
      newValues.splice(index, 1);
    } else {
      newValues.push(value);
    }

    setValues(newValues);
    onChange({ target: { name, value: newValues } });
  };

  return (
    <div>
      <p className={`${activeTheme.title} text-sm font-bold`}>{title}</p>
      <div className="mt-2 grid grid-cols-4 gap-1 sm:grid-cols-7 md:grid-cols-4">
        {data.map((item, index) => (
          <button
            className={`${activeTheme.option.all} ${activeTheme.option.hover}
              ${
                values.includes(item.value)
                  ? activeTheme.option.active
                  : activeTheme.option.inactive
              } flex flex-col items-center gap-1 border p-2`}
            key={index}
            name={name}
            onClick={() => handleClick(item.value)}
          >
            <img className="object-cover" alt={item.label} src={item.icon} />
            <p className="text-xs font-bold">{item.label}</p>
          </button>
        ))}
      </div>
    </div>
  );
}
