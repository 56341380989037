import bandImg1 from "../../assets/images/slider-mini/28136 and 30027.avif";
import bandImg2 from "../../assets/images/slider-mini/29080.avif";
import bandImg4 from "../../assets/images/slider-mini/30864 and 30368.avif";

import braceletImg1 from "../../assets/images/slider-mini/30385.avif";
import braceletImg2 from "../../assets/images/slider-mini/30897.avif";
import braceletImg3 from "../../assets/images/slider-mini/30945.avif";
import braceletImg4 from "../../assets/images/slider-mini/31141.avif";
import braceletImg5 from "../../assets/images/slider-mini/31200.avif";
import braceletImg6 from "../../assets/images/slider-mini/31215.avif";
import braceletImg7 from "../../assets/images/slider-mini/31238.avif";

import necklaceImg1 from "../../assets/images/slider-mini/31025.avif";
import necklaceImg2 from "../../assets/images/slider-mini/31035.avif";
import earringImg1 from "../../assets/images/slider-mini/31090.avif";
import earringImg2 from "../../assets/images/slider-mini/31115-2.avif";
import earringImg3 from "../../assets/images/slider-mini/31115.avif";

import ringImg1 from "../../assets/images/slider-mini/27473.avif";
import ringImg2 from "../../assets/images/slider-mini/29567-2.avif";
import ringImg3 from "../../assets/images/slider-mini/29567.avif";
import ringImg4 from "../../assets/images/slider-mini/29611.avif";
import ringImg5 from "../../assets/images/slider-mini/30036.avif";
import ringImg6 from "../../assets/images/slider-mini/30377.avif";
import ringImg7 from "../../assets/images/slider-mini/30520.avif";
import ringImg8 from "../../assets/images/slider-mini/31080.avif";

export const data = [
  {
    href: "/jewelry",
    title: "Rings",
    description:
      "Indulge in the timeless allure of our ring collection. Each piece is exquisitely designed, featuring hand-selected diamonds meticulously set in the highest quality platinum or 18k gold. With every glimmer, these rings symbolize refined elegance and enduring beauty. Elevate your style with a touch of sophistication that lasts a lifetime.",
    slider: [
      {
        image: {
          src: ringImg1,
        },
      },
      {
        image: {
          src: ringImg2,
        },
      },
      {
        image: {
          src: ringImg3,
        },
      },
      {
        image: {
          src: ringImg4,
        },
      },
      {
        image: {
          src: ringImg5,
        },
      },
      {
        image: {
          src: ringImg6,
        },
      },
      {
        image: {
          src: ringImg7,
        },
      },
      {
        image: {
          src: ringImg8,
        },
      },
    ],
  },
  {
    href: "/jewelry",
    title: "Bands",
    description:
      "Mark your everlasting love with our luxe bands. Each piece is a masterpiece of craftsmanship, available in platinum, rose, or yellow gold, intricately detailed with diamonds or smooth, lustrous finishes. A symbol of commitment, these bands promise timeless style and exclusivity, celebrating your bond with unmatched refinement.",
    slider: [
      {
        image: {
          src: bandImg1,
        },
      },
      {
        image: {
          src: bandImg2,
        },
      },
      {
        image: {
          src: bandImg4,
        },
      },
    ],
  },
  {
    href: "/jewelry",
    title: "Earrings",
    description:
      "Experience unparalleled luxury with our diamond-encrusted earrings. Crafted with precision, these earrings feature cascading diamonds that elegantly capture and reflect light, creating a soft, mesmerizing glow. Whether for a special occasion or to add a touch of elegance to your daily wear, their timeless design ensures you exude effortless sophistication wherever you go.",
    slider: [
      {
        image: {
          src: earringImg1,
        },
      },
      {
        image: {
          src: earringImg2,
        },
      },
      {
        image: {
          src: earringImg3,
        },
      },
    ],
  },
  {
    href: "/jewelry",
    title: "Necklaces",
    description:
      "Discover the essence of harmony with our necklaces. Featuring brilliant center stones embraced by a circle of delicate diamonds, each pendant is suspended on a fine chain that complements its luminous beauty. This necklace is a symbol of elegance, designed to accentuate the natural grace of the wearer.",
    slider: [
      {
        image: {
          src: necklaceImg1,
        },
      },
      {
        image: {
          src: necklaceImg2,
        },
      },
    ],
  },
  {
    href: "/jewelry",
    title: "Bracelets",
    description:
      "Bask in the brilliance of our diamond bracelets, a testament to meticulous craftsmanship. These delicate yet striking pieces are adorned with flawless round-cut diamonds, each encased in pristine bezel settings. Every bracelet ensures an exquisite fit that glides effortlessly on your wrist, radiating grace with every movement.",
    slider: [
      {
        image: {
          src: braceletImg1,
        },
      },
      {
        image: {
          src: braceletImg2,
        },
      },
      {
        image: {
          src: braceletImg3,
        },
      },
      {
        image: {
          src: braceletImg4,
        },
      },
      {
        image: {
          src: braceletImg5,
        },
      },
      {
        image: {
          src: braceletImg6,
        },
      },
      {
        image: {
          src: braceletImg7,
        },
      },
    ],
  },
];
