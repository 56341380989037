// assets
import BannerImage from "../assets/images/banners/diamonds.jpg";

// components (template)
import Header from "../components/Header";
import ImageBanner from "../components/ImageBanner";
import StandardContainer from "../components/StandardContainer";

// data
import { faq } from "../data/faq";

export default function Faq() {
  const content = (
    <>
      {Object.entries(faq).map(([question, answer], index) => (
        <section key={index} className="mb-4 mt-6">
          <h2 className="text-xl font-bold">{question}</h2>
          <p>{answer}</p>
        </section>
      ))}
    </>
  );
  return (
    <>
      <Header title="FAQ's" />
      <ImageBanner image={BannerImage} />
      <StandardContainer content={content} />
    </>
  );
}
