// packages
import _ from "lodash";

export default function ItemDetails({ item, keys = [], gridClass = "" }) {
  return (
    <div
      className={`${gridClass} columns-3 gap-x-4 gap-y-2 rounded-md bg-white p-2`}
    >
      {keys.map((key) => {
        return (
          <div
            className="flex justify-between border-b border-dashed border-gray-400 py-1 text-sm"
            key={key}
          >
            <dt className="italic">
              {_.startCase(
                _.replace(_.replace(key, "data.", ""), "details.", ""),
              )}
            </dt>
            <dd className="font-semibold">{_.get(item, key)}</dd>
          </div>
        );
      })}
    </div>
  );
}
