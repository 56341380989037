import axios from "axios";

export async function fetchBookmarks(page) {
  const url = new URL(`${process.env.REACT_APP_API_URL}/bookmarks.json`);
  url.searchParams.append("page", page);

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: localStorage.token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function addBookmark(item, idParam) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/bookmarks`,
      {
        bookmark: { [idParam]: item.id },
      },
      {
        headers: {
          Authorization: localStorage.token,
        },
      },
    );

    item.is_bookmarked = true;
    return item;
  } catch (error) {
    throw error;
  }
}

export async function removeBookmark(item, idParam) {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/bookmarks/${item.id}`,
      {
        data: {
          bookmark: { [idParam]: item.id },
        },
        headers: {
          Authorization: localStorage.token,
        },
      },
    );

    item.is_bookmarked = false;
    return item;
  } catch (error) {
    throw error;
  }
}

export function toggleBookmark(item) {
  const idParam = item.type == "jewelry" ? "jewelry_id" : "stone_id";

  if (item.is_bookmarked) {
    return removeBookmark(item, idParam);
  } else {
    return addBookmark(item, idParam);
  }
}
