// packages
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

// components
import Header from "../components/Header";
import ItemDetails from "../shared/components/ItemDetails";
import LoadingIndicator from "../shared/components/LoadingIndicator";
import QuoteList from "../components/QuoteList";
import StandardContainer from "../components/StandardContainer";

// TODO: rework similar to Profile page
export default function User() {
  const { id } = useParams();

  //////////////////////////////////////////////////////////////////////////////
  // data
  //////////////////////////////////////////////////////////////////////////////

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/${id}.json`, {
        headers: {
          Authorization: localStorage.token,
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const content = loading ? (
    <LoadingIndicator message="Loading user details" />
  ) : (
    <>
      {data && (
        <>
          <ItemDetails item={data} keys={["full_name", "email", "role"]} />
          <h2 className="mt-2 bg-gray-50 p-2 text-2xl font-bold leading-none text-theme-blue">
            Quotes
          </h2>
          <QuoteList userId={data.id} />
        </>
      )}
    </>
  );

  return (
    <>
      <Header title="User Details" />
      <StandardContainer content={content} />
    </>
  );
}
