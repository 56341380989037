// packages
// import { NavLink } from "react-router-dom";

export default function ItemGrid({ data, limit = false, showSummary = false }) {
  const selectedData = limit ? data.slice(0, limit) : data;

  return (
    <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-4 md:gap-4">
      {selectedData.map((item, index) => {
        return (
          <a
            className="group border border-black/10 p-4 text-black hover:border-cartier-red"
            href={item.route}
            key={index}
            target="_blank"
          >
            <h4 className="mb-2 h-12 text-center">{item.title}</h4>
            {item.images && item.images.main && (
              <div className="flex justify-center overflow-hidden py-2">
                <img
                  alt={item.images.main.alt}
                  className="max-h-36 object-cover transition-all duration-300 group-hover:scale-110"
                  loading="lazy"
                  src={item.images.main.src}
                />
              </div>
            )}
            <div
              className={`${showSummary ? "justify-between" : "justify-center"} mt-2 flex items-center gap-2`}
            >
              {showSummary && <p className="text-sm">{item.summary}</p>}
              <p className="text-lg font-bold">{item.price}</p>
            </div>
            <div className="mt-2 block bg-cartier-red py-2 text-center text-white hover:bg-gray-800 group-hover:opacity-100 max-lg:hidden lg:opacity-0">
              View
            </div>
          </a>
        );
      })}
    </div>
  );
}
