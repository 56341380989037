import { jwtDecode } from "jwt-decode";

export function isTokenValid() {
  if (!localStorage.token) {
    return false;
  }

  const decodedToken = jwtDecode(localStorage.token);
  return decodedToken.exp * 1000 > Date.now();
}
