// packages
import {
  MdOutline360,
  MdOutlineLink,
  MdOutlineLoupe,
  MdOutlinePlayCircle,
} from "react-icons/md";

// assets
import DiagramSide from "../../assets/images/diamond-diagram-side.svg";
import DiagramTop from "../../assets/images/diamond-diagram-top.svg";
import GiaLogo from "../../assets/images/gia-logo.svg";

export default function ItemGallery({ data }) {
  return (
    <div className="flex flex-col items-start gap-x-8 lg:flex-row">
      {/* image gallery */}
      <div className="w-full lg:order-last">
        <img
          alt={data.images.main.alt}
          className="object-fill"
          loading="lazy"
          src={data.images.main.src}
        />
      </div>
      {/* control panel */}
      <div className="mt-2 grid w-full grid-cols-3 gap-2 bg-gray-50 p-2 md:mt-0 md:grid-cols-6 lg:w-2/12 lg:grid-cols-1">
        {data.videoUrl && (
          <a
            href={data.videoUrl}
            target="_blank"
            className="group flex items-center justify-center overflow-hidden border-2 border-gray-200 bg-white p-4 hover:border-gray-200"
          >
            <MdOutlinePlayCircle className="h-8 w-8 shrink-0 fill-gray-400 group-hover:scale-110 lg:h-12 lg:w-12" />
          </a>
        )}
        <a
          href="#"
          className="group flex items-center justify-center overflow-hidden border-2 border-gray-200 bg-white p-4 hover:border-gray-200"
        >
          <MdOutlineLoupe className="h-8 w-8 shrink-0 fill-gray-400 group-hover:scale-110 lg:h-12 lg:w-12" />
        </a>
        <a
          href="#"
          className="group flex items-center justify-center overflow-hidden border-2 border-gray-200 bg-white p-4 hover:border-gray-200"
        >
          <MdOutline360 className="h-8 w-8 shrink-0 fill-gray-400 group-hover:scale-110 lg:h-12 lg:w-12" />
        </a>
        {data.type === "diamond" && (
          <>
            <a
              href="#"
              className="group flex items-center justify-center overflow-hidden border-2 border-gray-200 bg-white p-4 hover:border-gray-200"
            >
              <img
                alt=""
                className="object-cover group-hover:scale-110"
                src={DiagramTop}
              />
            </a>
            <a
              href="#"
              className="group flex items-center justify-center overflow-hidden border-2 border-gray-200 bg-white p-4 hover:border-gray-200"
            >
              <img
                alt=""
                className="object-cover group-hover:scale-110"
                src={DiagramSide}
              />
            </a>
            {data.certificateUrl && (
              <a
                href={data.certificateUrl}
                className="group block overflow-hidden border-2 border-gray-200 bg-white p-4 hover:border-gray-200"
              >
                <img
                  alt=""
                  className="h-full w-full group-hover:scale-110"
                  src={GiaLogo}
                />
              </a>
            )}
          </>
        )}
        {data.webUrl && (
          <a
            href={data.webUrl}
            target="_blank"
            className="group flex items-center justify-center overflow-hidden border-2 border-gray-200 bg-white p-4 hover:border-gray-200"
          >
            <MdOutlineLink className="h-8 w-8 shrink-0 fill-gray-400 group-hover:scale-110 lg:h-12 lg:w-12" />
          </a>
        )}
      </div>
    </div>
  );
}
