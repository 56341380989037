import { useRef, useState } from "react";
import axios from "axios";
import _ from "lodash";

import Alert from "../shared/components/Alert";
import Tabs from "../shared/components/Tabs";

const DEFAULT_THEME = {
  // own settings
  button: {
    primary:
      "bg-theme-blue text-white rounded-md hover:bg-black active:bg-black/50",
  },
  checkbox:
    "border-gray-400 bg-gray-50 rounded hover:bg-gray-200 checked:bg-theme-blue checked:hover:bg-theme-blue/50 checked:focus:bg-theme-blue/50",
  container: "bg-theme-blue",
  input:
    "border-gray-400 bg-gray-50 text-gray-800 rounded-md focus:border-theme-blue",
  label: "text-gray-800",
  link: "text-theme-blue hover:text-gray-800 hover:underline",
  title: "text-gray-600 leading-tight tracking-tight",
  window: "rounded-md bg-white",

  // component settings
  tabTheme: {},
};

export default function RequestPasswordReset({ theme = {} }) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  const [alertMessage, setAlertMessage] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);

  const [verifyMessage, setVerifyMessage] = useState("");
  const [verifyVisible, setVerifyVisible] = useState(false);

  // email verify

  const emailVerifyForm = useRef();
  const handleRequestPasswordReset = async (e) => {
    e.preventDefault();
    setAlertVisible(false);
    const formData = new FormData(emailVerifyForm.current);
    const entries = Object.fromEntries(formData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/password`,
        {
          user: { email: entries.email },
        },
      );
      setVerifyVisible(true);
      setVerifyMessage("Please Check Your Email");
    } catch (error) {
      console.error("Error email verify:", error);
      e.preventDefault();
      setAlertVisible(true);
      setAlertMessage("Wrong email, please try again.");
    }
  };

  const tabData = [
    {
      label: "Forgot Password?",
      content: (
        <div
          className={`${activeTheme.container} mx-auto flex flex-col items-center justify-center p-4 lg:py-6 xl:py-8`}
          // md:h-[calc(100vh-8rem)]
        >
          <div className="w-full md:max-w-md">
            {alertVisible && (
              <Alert
                content={alertMessage}
                type="error"
                visible={alertVisible}
              />
            )}
            <div className={activeTheme.window}>
              {verifyVisible ? (
                <Alert
                  content={verifyMessage}
                  type="success"
                  visible={verifyVisible}
                />
              ) : (
                <div className="space-y-4 p-6 sm:p-8">
                  <h1
                    className={`${activeTheme.title} mb-8 text-xl font-bold md:text-2xl`}
                  >
                    Request Password Reset
                  </h1>
                  <form
                    className="space-y-4"
                    onSubmit={handleRequestPasswordReset}
                    ref={emailVerifyForm}
                  >
                    <div>
                      <label
                        className={`${activeTheme.label} block text-sm font-medium`}
                        htmlFor="lEmail"
                      >
                        Email
                      </label>
                      <input
                        className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
                        id="lEmail"
                        name="email"
                        placeholder="Your email address"
                        required="required"
                        type="email"
                      />
                    </div>

                    <button
                      className={`${activeTheme.button.primary} w-full px-5 py-2.5 text-center text-sm font-bold focus:ring-0`}
                      type="submit"
                    >
                      Get Reset Email
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      ),
    },
  ];

  return <Tabs data={tabData} theme={activeTheme.tabTheme} />;
}
