// packages
import { NavLink } from "react-router-dom";

export default function Categories({
  data,
  title,
  path,
  param,
  imageCssClasses,
}) {
  return (
    <>
      {title && (
        <h2 className="mb-4 border-none text-center text-2xl md:text-3xl">
          {title}
        </h2>
      )}
      <div className="flex flex-row flex-wrap justify-between gap-y-4">
        {data.map((item, index) => {
          return (
            <NavLink
              className="group flex basis-1/4 flex-col items-center gap-2 overflow-hidden border-none text-gray-700 sm:basis-1/4 lg:basis-[8%]"
              key={index}
              to={`${path}`}
            >
              <img
                alt={item.name}
                className={`${imageCssClasses} h-full object-scale-down pt-1 transition-all duration-300 group-hover:scale-110`}
                src={item.image}
              />
              <p className="font-semibold transition-all duration-300 group-hover:translate-y-1">
                {item.name}
              </p>
            </NavLink>
          );
        })}
      </div>
    </>
  );
}
