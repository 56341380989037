// packages
import { MdMenu, MdOutlineAccountCircle } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// assets
import Logo from "../assets/logos/logo.jpg";

// data
import { meta } from "../data/meta";
import { navbar } from "../data/nav";

// utils
import { isTokenValid } from "../shared/utils/auth";
import { isCompanyUser, isSuperAdminUser } from "../shared/utils/user";

const PROFILE_KEY = "profile";
const TOKEN_KEY = "token";

export default function Navbar() {
  const [openMainMenu, setOpenMainMenu] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 0.2;
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const maxScrollableHeight = documentHeight - windowHeight;

      if (scrollTop / maxScrollableHeight > scrollThreshold) {
        setShowLogo(false);
      } else {
        setShowLogo(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showLogo]);

  //////////////////////////////////////////////////////////////////////////////
  // user
  //////////////////////////////////////////////////////////////////////////////

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    if (!isTokenValid()) {
      localStorage.removeItem(PROFILE_KEY);
    } else {
      setIsAuthenticated(true);

      if (localStorage.getItem(PROFILE_KEY)) {
        setProfile(JSON.parse(localStorage.getItem(PROFILE_KEY)));
      }
    }
  }, [localStorage.getItem(TOKEN_KEY)]);

  const handleLogout = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/logout`, {
        headers: {
          Authorization: localStorage.getItem(TOKEN_KEY),
        },
      });

      localStorage.removeItem(PROFILE_KEY);
      localStorage.removeItem(TOKEN_KEY);
      setProfile({});
      setIsAuthenticated(false);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
      alert("Error signing out, please try again.");
    }
  };

  const userNavData = {
    signedIn: [
      {
        href: "/quotes",
        text: "Quotes",
      },
      {
        href: "/bookmarks",
        text: "Bookmarks",
      },
      {
        href: "/profile",
        text: "Profile",
      },
      ...(isCompanyUser(profile.role)
        ? [
            {
              href: "/company",
              text: "Company",
            },
          ]
        : []),
      ...(isSuperAdminUser(profile.role)
        ? [
            {
              href: "/company",
              text: "Company",
            },
          ]
        : []),
      ...(isCompanyUser(profile.role) || isSuperAdminUser(profile.role)
        ? [
            {
              href: "/users",
              text: "Users",
            },
          ]
        : []),
      {
        href: "#",
        text: "Sign Out",
        onClick: (e) => handleLogout(e),
      },
    ],
    signedOut: [
      // {
      //   href: "sign-in",
      //   text: "Sign In",
      // },
      // {
      //   href: "/sign-up",
      //   text: "Sign Up",
      // },
    ],
  };

  const renderUserNav = () => {
    const userNav = isAuthenticated
      ? userNavData.signedIn
      : userNavData.signedOut;

    return (
      <>
        <ul className="" aria-labelledby="user-menu-button">
          {userNav.map((item, index) => (
            <li key={index}>
              <NavLink
                className="block px-4 text-lg font-bold uppercase transition-all duration-200 hover:border-black hover:text-black"
                to={item.href}
                {...(item.onClick ? { onClick: item.onClick } : {})}
              >
                {item.text}
              </NavLink>
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <>
      <div className="hidden p-4 md:flex md:justify-end">
        <button
          aria-expanded="false"
          className="flex text-sm text-theme-red max-md:mr-1"
          data-dropdown-placement="bottom"
          data-dropdown-toggle="user-menu"
          id="user-menu-button"
          type="button"
        >
          {!isAuthenticated ? (
            <NavLink to="/sign-in">Sign In</NavLink>
          ) : (
            <>
              <span className="sr-only">Open user menu</span>
              <MdOutlineAccountCircle size="24" className="shrink-0" />
            </>
          )}
        </button>

        <div
          className="z-50 my-4 hidden list-none divide-y divide-gray-100 rounded-md bg-white shadow"
          id="user-menu"
        >
          {isAuthenticated && (
            <div className="px-4 py-3">
              <span className="block text-sm font-semibold text-gray-800">
                {profile.full_name}
              </span>
              <span className="block truncate text-sm text-gray-500">
                {profile.email}
              </span>
            </div>
          )}
          {renderUserNav()}
        </div>
      </div>

      <nav
        className={`${
          showLogo
            ? "border-transparent"
            : "fixed top-0 border-gray-200 shadow-md"
        } z-50 w-full border-b-2 bg-[#FBFCFC] transition-all duration-0`}
      >
        <NavLink to="/">
          <h1
            className={`${
              !showLogo ? "hidden" : "block"
            } flex items-center justify-center pb-4 pt-12 text-5xl font-semibold text-gray-700 hover:text-cartier-red max-md:hidden max-md:flex-col`}
          >
            <img
              src={Logo}
              alt={meta.name}
              className="max-w-80 object-contain"
            />
          </h1>
        </NavLink>
        <div className="flex flex-wrap items-center justify-between max-md:p-2 md:flex-row-reverse md:justify-center">
          <NavLink to="/">
            <img
              src={Logo}
              alt={meta.name}
              className="h-12 object-contain sm:h-16 md:hidden"
            />
          </NavLink>
          <div className="flex items-center">
            <div className="max-md:pr-4 md:hidden">
              <button
                aria-expanded="false"
                className="flex text-sm text-theme-red max-md:mr-1"
                data-dropdown-placement="bottom"
                data-dropdown-toggle="user-menu-large"
                id="user-menu-button"
                type="button"
              >
                {!isAuthenticated ? (
                  <NavLink to="/sign-in">Sign In</NavLink>
                ) : (
                  <>
                    <span className="sr-only">Open user menu</span>
                    <MdOutlineAccountCircle size="24" className="shrink-0" />
                  </>
                )}
              </button>
              <div
                className="z-50 my-4 hidden list-none divide-y divide-gray-100 rounded-md bg-white shadow"
                id="user-menu-large"
              >
                {isAuthenticated && (
                  <div className="px-4 py-3">
                    <span className="block text-sm font-semibold text-gray-800">
                      {profile.full_name}
                    </span>
                    <span className="block truncate text-sm text-gray-500">
                      {profile.email}
                    </span>
                  </div>
                )}
                {renderUserNav()}
              </div>
            </div>
            <div className="flex items-center md:order-2">
              <button
                className="block text-theme-red md:hidden"
                onClick={() => setOpenMainMenu(!openMainMenu)}
              >
                <MdMenu size="32" className="shrink-0" />
              </button>
            </div>
          </div>
          <div
            className={`${openMainMenu ? "h-48" : "max-md:h-0"} w-full items-center justify-between overflow-hidden transition-all duration-300 md:order-1 md:flex md:w-auto`}
          >
            <ul className="flex flex-col max-md:mt-2 max-md:space-y-1 max-md:px-4 md:flex-row md:space-x-8 md:py-4">
              {Object.keys(navbar).map((link, index) => {
                return (
                  <li key={index}>
                    <NavLink
                      onClick={() => {
                        if (window.innerWidth <= 768) {
                          setOpenMainMenu(!openMainMenu);
                        }
                      }}
                      className={({ isActive }) =>
                        `border-b-2 border-white text-lg font-bold uppercase transition-all duration-200 hover:border-black hover:text-black
                      ${isActive && "border-cartier-red md:text-black"}`
                      }
                      exact="exact"
                      to={navbar[link].href}
                    >
                      {navbar[link].text}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
