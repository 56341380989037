////////////////////////////////////////////////////////////////////////////////
// packages
////////////////////////////////////////////////////////////////////////////////

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "flowbite";

////////////////////////////////////////////////////////////////////////////////
// assets
////////////////////////////////////////////////////////////////////////////////

import "./index.css";

////////////////////////////////////////////////////////////////////////////////
// components
////////////////////////////////////////////////////////////////////////////////

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Promos from "./components/Promos";
import ScrollToTop from "./components/ScrollToTop";

////////////////////////////////////////////////////////////////////////////////
// shared pages
////////////////////////////////////////////////////////////////////////////////

import DiamondsWrapper from "./shared/pages/DiamondsWrapper";
import JewelriesWrapper from "./shared/pages/JewelriesWrapper";
import Login from "./shared/pages/Login";

////////////////////////////////////////////////////////////////////////////////
// template pages
////////////////////////////////////////////////////////////////////////////////

import About from "./pages/About";
import Bookmarks from "./pages/Bookmarks";
import Company from "./pages/Company";
import Contact from "./pages/Contact";
import Diamonds from "./pages/Diamonds";
import Faq from "./pages/Faq";
import Home from "./pages/Home";
import Item from "./pages/Item";
import Jewelries from "./pages/Jewelries";
import Locations from "./pages/Locations";
import PasswordReset from "./pages/PasswordReset";
import Process from "./pages/Process";
import Profile from "./pages/Profile";
import Quote from "./pages/Quote";
import QuoteCart from "./pages/QuoteCart";
import Quotes from "./pages/Quotes";
import RequestPasswordReset from "./pages/RequestPasswordReset";
import Showcase from "./pages/Showcase";
import User from "./pages/User";
import Users from "./pages/Users";

////////////////////////////////////////////////////////////////////////////////

export default function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/company" element={<Company />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/locations" element={<Locations />} />
          <Route path="/process" element={<Process />} />
          <Route path="/showcase" element={<Showcase />} />
          <Route
            path="/sign-in"
            element={
              <Login
                theme={{
                  // own settings
                  button: {
                    primary:
                      "bg-cartier-red text-white tracking-wider uppercase hover:bg-black active:bg-black/50",
                  },
                  checkbox:
                    "border-gray-400 bg-gray-50 hover:bg-gray-200 checked:bg-cartier-red checked:hover:bg-cartier-red/50 checked:focus:bg-cartier-red/50",
                  container: "bg-cartier-red mb-2 md:mb-4",
                  input:
                    "border-gray-400 bg-gray-50 font-bold text-gray-800 focus:border-cartier-red",
                  label: "font-semibold text-gray-800",
                  link: "font-semibold",
                  title: "text-gray-600",
                  window: "bg-white",

                  // component settings
                  tabTheme: {
                    container: "bg-black",
                    button: {
                      all: "w-full block font-bold text-lg text-cartier-red tracking-wide hover:border-gray-200",
                      active: "border-white bg-cartier-red text-white",
                      inactive:
                        "border-transparent text-gray-300 hover:text-white",
                    },
                    label: "w-full",
                  },
                }}
              />
            }
          />
          <Route
            path="/profile"
            element={
              <Profile
                theme={{
                  // own settings
                  button: {
                    primary:
                      "bg-cartier-red text-white tracking-wider uppercase hover:bg-black active:bg-black/50",
                  },
                  container: "bg-cartier-red mb-2 md:mb-4",
                  input:
                    "border-gray-400 bg-gray-50 font-bold text-gray-800 focus:border-cartier-red focus:ring-cartier-red",
                  link: "font-semibold text-cartier-red",
                }}
              />
            }
          />
          <Route path="/quotes" element={<Quotes />} />
          <Route
            path="/quotes/:id"
            element={<Quote routeShow="/quotes/:id" />}
          />
          <Route path="/bookmarks" element={<Bookmarks />} />
          <Route
            path="/request-password-reset"
            element={
              <RequestPasswordReset
                theme={{
                  // own settings
                  button: {
                    primary:
                      "bg-cartier-red text-white tracking-wider uppercase hover:bg-black active:bg-black/50",
                  },
                  checkbox:
                    "border-gray-400 bg-gray-50 hover:bg-gray-200 checked:bg-cartier-red checked:hover:bg-cartier-red/50 checked:focus:bg-cartier-red/50",
                  container: "bg-cartier-red mb-2 md:mb-4",
                  input:
                    "border-gray-400 bg-gray-50 font-bold text-gray-800 focus:border-cartier-red",
                  label: "font-semibold text-gray-800",
                  link: "font-semibold",
                  title: "text-gray-600",
                  window: "bg-white",

                  // component settings
                  tabTheme: {
                    container: "bg-black",
                    button: {
                      all: "w-full block font-bold text-lg text-cartier-red tracking-wide hover:border-gray-200",
                      active: "border-white bg-cartier-red text-white",
                      inactive:
                        "border-transparent text-gray-300 hover:text-white",
                    },
                    label: "w-full",
                  },
                }}
              />
            }
          />
          <Route
            path="/password-reset/:resetToken"
            element={
              <PasswordReset
                theme={{
                  // own settings
                  button: {
                    primary:
                      "bg-cartier-red text-white tracking-wider uppercase hover:bg-black active:bg-black/50",
                  },
                  checkbox:
                    "border-gray-400 bg-gray-50 hover:bg-gray-200 checked:bg-cartier-red checked:hover:bg-cartier-red/50 checked:focus:bg-cartier-red/50",
                  container: "bg-cartier-red mb-2 md:mb-4",
                  input:
                    "border-gray-400 bg-gray-50 font-bold text-gray-800 focus:border-cartier-red",
                  label: "font-semibold text-gray-800",
                  link: "font-semibold",
                  title: "text-gray-600",
                  window: "bg-white",

                  // component settings
                  tabTheme: {
                    container: "bg-black",
                    button: {
                      all: "w-full block font-bold text-lg text-cartier-red tracking-wide hover:border-gray-200",
                      active: "border-white bg-cartier-red text-white",
                      inactive:
                        "border-transparent text-gray-300 hover:text-white",
                    },
                    label: "w-full",
                  },
                }}
              />
            }
          />
          <Route
            path="/quotes/cart"
            element={<QuoteCart routeShow="/quotes/cart" />}
          />
          <Route
            path="/diamonds"
            element={<DiamondsWrapper page={Diamonds} routeShow="/diamonds" />}
          />
          <Route path="/diamonds/:id" element={<Item type="diamond" />} />
          <Route
            path="/jewelry"
            element={<JewelriesWrapper page={Jewelries} routeShow="/jewelry" />}
          />
          <Route path="/jewelry/:id" element={<Item type="jewelry" />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/:id" element={<User />} />
        </Routes>
        <Promos />
        <Footer />
      </BrowserRouter>
    </>
  );
}
