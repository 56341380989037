// packages
import { useState, useEffect } from "react";
import _ from "lodash";
import { MdOutlineShoppingCart } from "react-icons/md";

// components
import Header from "../components/Header";
import StandardContainer from "../components/StandardContainer";
import QuoteList from "../components/QuoteList";

// utils
import { loadQuoteItems } from "../shared/utils/quote";

export default function Quotes() {
  //////////////////////////////////////////////////////////////////////////////
  // data
  //////////////////////////////////////////////////////////////////////////////

  const quoteItems = loadQuoteItems();

  //////////////////////////////////////////////////////////////////////////////
  // user
  //////////////////////////////////////////////////////////////////////////////

  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const profile = JSON.parse(localStorage.profile);
    setUserRole(profile.role);
  }, []);

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const content = <QuoteList userRole={userRole} />;

  return (
    <>
      <Header
        title="Quotes"
        actions={[
          quoteItems.length > 0 && (
            <a
              className="flex w-fit items-center rounded-md bg-theme-blue px-2 py-2.5 text-sm font-medium text-white hover:bg-black"
              href="/quotes/cart"
              title="View quote cart"
            >
              <MdOutlineShoppingCart className="mr-1 shrink-0" size="16" />
              <span className="mr-2">{quoteItems.length}</span>
              Items In Quote Cart
            </a>
          ),
        ]}
      />
      <StandardContainer content={content} />
    </>
  );
}
