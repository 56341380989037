// assets
import {
  FaFacebook,
  FaInstagram,
  // FaPinterest,
  // FaTiktok,
  // FaYoutube,
} from "react-icons/fa";

export const navbar = [
  {
    href: "/diamonds",
    text: "Diamonds",
  },
  {
    href: "/jewelry",
    text: "Jewelry",
  },
  {
    href: "/showcase",
    text: "Catalog",
  },
  {
    href: "/about",
    text: "About",
  },
  {
    href: "/contact",
    text: "Contact",
  },
];

export const footer = {
  Inventory: {
    Diamonds: "/diamonds",
    Jewelry: "/jewelry",
    // Showcase: "/showcase",
  },
  // Information: {
  //   "How To Order": "/process",
  //   FAQ: "/faq",
  // },
  Company: {
    About: "/about",
    Contact: "/contact",
    // Locations: "/locations",
    // "Client Login": "/sign-in",
  },
  Contact: {
    Call: "tel:+12136244400",
    Chat: "https://wa.me/12136244400",
  },
  // Legal: {
  //   "Terms of Use": "#",
  //   "Privacy Policy": "#",
  //   "Conditions of Sale": "#",
  // },
};

export const social = {
  facebook: {
    icon: <FaFacebook />,
    url: "https://www.facebook.com/Ninacci/",
  },
  instagram: {
    icon: <FaInstagram />,
    url: "https://www.instagram.com/ninacci_diamonds/",
  },
  // pinterest: {
  //   icon: <FaPinterest />,
  //   url: "#",
  // },
  // tiktok: {
  //   icon: <FaTiktok />,
  //   url: "#",
  // },
  // youtube: {
  //   icon: <FaYoutube />,
  //   url: "#",
  // },
};
