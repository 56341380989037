import { useEffect, useState } from "react";
import axios from "axios";

export function useCompanySettings() {
  const [companySettings, setCompanySettings] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/companies/${process.env.REACT_APP_COMPANY_ID}/company_settings.json`,
        {
          headers: {
            Authorization: localStorage.token,
          },
        },
      )
      .then((response) => {
        setCompanySettings(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching company:", error);
      })
      .finally(() => {});
  }, []);

  return { companySettings };
}
