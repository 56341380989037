export default function Hero({ title, content, image }) {
  return (
    <div className="flex flex-col bg-gray-50 md:flex-row">
      {image && (
        <img
          className="object-cover object-center md:w-1/2"
          src={image}
          alt=""
        />
      )}
      {(title || content) && (
        <div className="flex flex-col items-center justify-center gap-4 py-4 md:w-1/2">
          {title && (
            <h1 className="text-4xl font-bold leading-none text-gray-700 max-md:px-4">
              {title}
            </h1>
          )}
          {content &&
            content.map((paragraph, index) => (
              <p className="px-4 text-lg lg:px-8 xl:px-20" key={index}>
                {paragraph}
              </p>
            ))}
        </div>
      )}
    </div>
  );
}
