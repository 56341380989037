// packages
import { useState } from "react";
import _ from "lodash";
import { loremIpsum } from "lorem-ipsum";

// assets
import BannerImage from "../assets/images/banners/showroom.avif";

// components (template)
import Hero from "../components/Hero";
import ModalInquiry from "../components/ModalInquiry";
import StandardContainer from "../components/StandardContainer";

// data
import { locations } from "../data/locations";

export default function Locations() {
  //////////////////////////////////////////////////////////////////////////////
  // modal
  //////////////////////////////////////////////////////////////////////////////

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locationName, setLocationName] = useState("");

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const content = (
    <div className="my-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:my-8 lg:grid-cols-3 lg:gap-8">
      {locations.map((location, index) => {
        return (
          <div key={index}>
            <h2 className="mb-2 text-center text-2xl font-bold">
              {location.city}
            </h2>
            <img
              alt={location.city}
              className="object-cover"
              src={location.image}
            />
            <div className="mb-3 mt-2 flex flex-col justify-between gap-2 md:flex-row">
              <button
                className="border-none bg-black px-4 py-3 font-cartier-sans text-sm font-bold text-white hover:bg-cartier-red hover:text-white md:w-1/2"
                onClick={() => {
                  setLocationName(location.city);
                  setIsModalOpen(true);
                }}
              >
                Book An Appointment
              </button>
              <a
                className="border-none bg-black px-4 py-3 font-cartier-sans text-sm text-white hover:bg-cartier-red hover:text-white md:w-1/2"
                href="#"
              >
                Get Directions
              </a>
            </div>
            <div className="flex flex-col justify-between gap-4 md:flex-row">
              <div className="flex flex-col justify-between md:w-1/2">
                <h3 className="text-lg">Address</h3>
                <p className="mb-3">
                  {location.address}
                  <br />
                  {`${location.city}, ${location.state} ${location.zip}`}
                </p>
                <h3 className="text-lg">Phone</h3>
                <a className="mb-3 border-none" href={`tel:${location.phone}`}>
                  {location.phone}
                </a>
                <h3 className="text-lg">Email</h3>
                <a className="border-none" href={`mailto:${location.email}`}>
                  {location.email}
                </a>
              </div>
              <div className="md:w-1/2">
                <h3 className="text-lg">Hours</h3>
                <ul className="mt-1 flex flex-col space-y-1">
                  {_.map(location.hours, (value, key) => (
                    <li className="flex justify-between" key={key}>
                      <span>{key}</span>
                      <span>{value}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      <Hero
        title="Locations"
        image={BannerImage}
        content={[
          loremIpsum({
            count: 4,
            sentenceLowerBound: 16,
            sentenceUpperBound: 20,
          }),
        ]}
      />
      <StandardContainer content={content} />
      <ModalInquiry
        header={`Book An Appointment At Our ${locationName} Boutique`}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        submitText="Book My Appointment"
        theme={{
          button: {
            primary:
              "bg-cartier-red text-white hover:bg-black active:bg-black/50",
          },
          header: "bg-black text-white",
          input:
            "border-gray-400 bg-gray-50 text-gray-800 focus:border-cartier-red",
          label: "font-semibold text-gray-800",
          overlay: "bg-black/80",
          window: "bg-white",
        }}
      />
    </>
  );
}
