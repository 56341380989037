// packages
import { useState, useEffect } from "react";
import _ from "lodash";
import classNames from "classnames";
import { MdOutlineGridView, MdOutlineTableRows } from "react-icons/md";
import Select from "react-select";

// classes
import Jewelry from "../classes/Jewelry";

// components
import Accordion from "./Accordion";
import Compare from "./Compare";
import ItemGrid from "./ItemGrid";
import Pagination from "./Pagination";
import PickItems from "./PickItems";
import RangeNumeric from "./RangeNumeric";
import SelectImages from "./SelectImages";
import Table from "./Table";
import Tabs from "./Tabs";

// contexts
import { useComparison } from "../contexts/ComparisonContext";
import { useSearch } from "../contexts/SearchContext";

// utils
import { formattedCurrency } from "../utils/currency";
import { isClientUser } from "../utils/user";

const DEFAULT_THEME = {
  accordion: {
    expandButton: {
      all: "",
      active: "bg-gray-600 text-white",
      inactive: "bg-cartier-red text-white",
    },
  },
  button: {
    primary: "bg-gray-600 text-white hover:bg-cartier-red hover:text-white",
  },
  compare: {
    button: {
      primary: "bg-gray-600 text-white hover:bg-cartier-red hover:text-white",
    },
  },
  input: "border-gray-600 focus:border-cartier-red",
  multiselect: {
    title: "text-black",
    select: {
      control: ({ isDisabled, isFocused }) =>
        classNames(
          !isDisabled && isFocused && "border-cartier-red",
          isFocused && "shadow-[0_0_0_1px] shadow-cartier-red",
          isFocused && "hover:border-cartier-red",
        ),
      option: ({ isDisabled, isFocused, isSelected }) =>
        classNames(
          isSelected && "bg-cartier-red",
          !isSelected && isFocused && "bg-cartier-red text-white",
          !isDisabled && isSelected && "active:bg-cartier-red",
          !isDisabled && !isSelected && "active:bg-cartier-red",
        ),
    },
  },
  radio:
    "border-gray-400 bg-gray-50 hover:bg-gray-200 checked:bg-cartier-red checked:hover:bg-cartier-red/50 checked:focus:bg-cartier-red/50",
  rangeNumeric: {
    input: "border-gray-600 focus:border-cartier-red",
    mark: "bg-cartier-red",
    thumb: "bg-cartier-red text-white focus:ring-cartier-red/40",
    title: "text-black",
    track: {
      active: "bg-cartier-red",
      inactive: "bg-gray-400",
    },
  },
  rangeValues: {
    label: {
      active: "text-black",
      inactive: "text-gray-400",
    },
    mark: "bg-cartier-red",
    thumb: "bg-cartier-red text-white focus:ring-cartier-red/40",
    title: "text-black",
    track: {
      active: "bg-cartier-red",
      inactive: "bg-gray-400",
    },
  },
  selectImages: {
    option: {
      all: "",
      active: "border-cartier-red bg-gray-50",
      hover: "hover:bg-gray-100",
      inactive: "border-white",
    },
    title: "text-black",
  },
};

export default function JewelryUI({ theme = {} }) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  //////////////////////////////////////////////////////////////////////////////
  // contexts
  //////////////////////////////////////////////////////////////////////////////
  const {
    // data
    results,

    // search
    searchParams,
    setSearchParams,
    setCurrentPage,
    searchProperties,
    // handleSearch,
    handleInputChange,
    fetchSearchProperties,
    expandAdvancedSearch,

    // reset
    handleReset,

    // sort
    // handleSort,

    // pagination
    currentPage,
    totalPages,
    handlePageChange,

    // routes
    routeShow,
  } = useSearch();

  const { comparisonData } = useComparison();

  //////////////////////////////////////////////////////////////////////////////
  // search
  //////////////////////////////////////////////////////////////////////////////

  const [paramDescription, setParamDescription] = useState(
    searchParams.description,
  );

  const handleParamDescription = (e) => {
    const previousValue = e.target.getAttribute("data-previous-value") || "";

    if (previousValue !== paramDescription) {
      setSearchParams((prevParams) => ({
        ...prevParams,
        description: paramDescription,
      }));
      setCurrentPage(1);
      handleInputChange(e);
    }
  };

  //////////////////////////////////////////////////////////////////////////////
  // user
  //////////////////////////////////////////////////////////////////////////////

  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    // const profile = JSON.parse(localStorage.profile);
    // setUserRole(profile.role);
    // fetchSearchProperties(profile.company_id);
    fetchSearchProperties(process.env.REACT_APP_COMPANY_ID);
  }, []);

  //////////////////////////////////////////////////////////////////////////////
  // table columns
  //////////////////////////////////////////////////////////////////////////////

  const tableColumns = () => {
    let columns = [
      {
        key: "categoryDetails",
        label: "Category",
        sortKey: "category",
      },
      {
        key: "data.category",
        label: "Category",
        sortKey: "category",
        link: true,
        path: routeShow,
      },
      {
        key: "data.sub_category",
        label: "Sub-Category",
        sortKey: "sub_category",
      },
      {
        key: "data.metal_1_type",
        label: "Metal Type",
        sortKey: "metal_1_type",
      },
      {
        key: "data.metal_1_karat",
        label: "Metal Karat",
        sortKey: "metal_1_karat",
      },
      {
        key: "data.metal_1_color",
        label: "Metal Color",
        sortKey: "metal_1_color",
      },
      {
        key: "details.metalWeight",
        label: "Metal Weight",
        sortKey: "metal_1_weight",
      },
    ];

    if (searchParams.description) {
      columns.push({
        key: "details.stockNumber",
        label: "Stock #",
        sortKey: "item_no",
      });
    }

    if (searchParams.size && searchParams.size.length > 0) {
      columns.push({ key: "data.size", label: "Size", sortKey: "size" });
    }

    columns.push({
      key: "price",
      label: "Price",
      sortKey: "price_total_1",
      classNames: "text-right",
    });

    return columns;
  };

  //////////////////////////////////////////////////////////////////////////////
  // item details
  //////////////////////////////////////////////////////////////////////////////

  const detailSettings = {
    keys: [
      "stockNumber",
      "category",
      "subCategory",
      "madeIn",
      "model",
      "style",
      "design",
      "size",
      "metalType",
      "metalKarat",
      "metalColor",
      "metalWeight",
    ],
  };

  //////////////////////////////////////////////////////////////////////////////
  // view switcher
  //
  // TODO: refactor into component to remove duplication
  //////////////////////////////////////////////////////////////////////////////

  const [viewType, setViewType] = useState(
    localStorage.getItem("viewType") || "grid",
  );

  useEffect(() => {
    localStorage.setItem("viewType", viewType);
  }, [viewType]);

  const paginationAndViewSwitcher = (
    <div className="flex justify-between">
      <Pagination
        currentPage={currentPage - 1}
        onPageChange={handlePageChange}
        pageCount={totalPages}
      />
      <div className="inline-flex">
        <button>
          <MdOutlineGridView
            className={`${viewType === "grid" && "text-cartier-red"} shrink-0`}
            onClick={() => setViewType("grid")}
            size={24}
          />
        </button>
        <button>
          <MdOutlineTableRows
            className={`${viewType === "table" && "text-cartier-red"} shrink-0`}
            onClick={() => setViewType("table")}
            size={24}
          />
        </button>
      </div>
    </div>
  );

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const tabData = [
    {
      label: "Search",
      content: (
        <div className="mt-2 flex flex-col gap-4 md:mt-4 md:flex-row">
          <aside className="flex h-auto flex-col gap-6 md:w-1/3 lg:w-1/4 2xl:w-1/5">
            <button
              className={`${activeTheme.button.primary} p-1.5 text-sm`}
              onClick={handleReset}
            >
              Reset Search
            </button>
            <SelectImages
              title="Category"
              name="category"
              selectedValues={searchParams.category}
              theme={activeTheme.selectImages}
              onChange={(e) => {
                setSearchParams((prevParams) => ({
                  ...prevParams,
                  category: e.target.value,
                }));
                setCurrentPage(1);
              }}
              data={Jewelry.Categories()}
            />
            {/* TODO: fix valueCalculator and positionCalculator */}
            <RangeNumeric
              title="Price"
              limitMax={searchProperties.price_max}
              limitMin={searchProperties.price_min}
              // markMax={239}
              // markMin={0}
              // marks={[10, 109, 149]}
              theme={activeTheme.rangeNumeric}
              valMax={searchParams.priceMax}
              valMin={searchParams.priceMin}
              callback={(min, max) => {
                setSearchParams((prevParams) => ({
                  ...prevParams,
                  priceMin: min,
                  priceMax: max,
                }));
                setCurrentPage(1);
              }}
              valueFormatter={(value) => {
                return formattedCurrency(value, 0);
              }}
            />
            {searchParams.category.length < 2 && (
              <div>
                <p
                  className={`${activeTheme.multiselect.title} mb-2 text-sm font-bold`}
                >
                  Category
                </p>
                <Select
                  isClearable={true}
                  isMulti={false}
                  classNames={activeTheme.multiselect.select}
                  onChange={(values, _action) => {
                    setSearchParams((prevParams) => ({
                      ...prevParams,
                      category: values ? [values.value] : [],
                    }));
                    setCurrentPage(1);
                  }}
                  options={
                    searchProperties.category &&
                    Object.keys(searchProperties.category)
                      .sort()
                      .map((item) => ({
                        label: item,
                        value: item,
                      }))
                  }
                  placeholder="Category"
                  value={
                    searchParams.category.length > 0 && {
                      label: searchParams.category,
                      value: searchParams.category,
                    }
                  }
                />
              </div>
            )}
            {searchParams.category.length === 1 && (
              <div>
                <p
                  className={`${activeTheme.multiselect.title} mb-2 text-sm font-bold`}
                >
                  Sub-Category
                </p>
                <Select
                  isClearable={true}
                  isMulti={true}
                  classNames={activeTheme.multiselect.select}
                  onChange={(values, _action) => {
                    setSearchParams((prevParams) => ({
                      ...prevParams,
                      subCategory: values.map((item) => item.value),
                    }));
                    setCurrentPage(1);
                  }}
                  options={_.get(
                    searchProperties.category,
                    searchParams.category,
                  )
                    .sort()
                    .map((item) => ({
                      label: item,
                      value: item,
                    }))}
                  placeholder="Sub-Category"
                  value={searchParams.subCategory.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              </div>
            )}
            <div>
              <p
                className={`${activeTheme.multiselect.title} mb-2 text-sm font-bold`}
              >
                Metal Type
              </p>
              <Select
                isClearable={true}
                isMulti={true}
                classNames={activeTheme.multiselect.select}
                onChange={(values, _action) => {
                  setSearchParams((prevParams) => ({
                    ...prevParams,
                    metalType: values.map((item) => item.value),
                  }));
                  setCurrentPage(1);
                }}
                options={
                  searchProperties.metal_type &&
                  searchProperties.metal_type.map((item) => ({
                    label: item,
                    value: item,
                  }))
                }
                placeholder="Metal Type"
                value={searchParams.metalType.map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
            </div>
            <div>
              <p
                className={`${activeTheme.multiselect.title} mb-2 text-sm font-bold`}
              >
                Metal Color
              </p>
              <Select
                isClearable={true}
                isMulti={true}
                classNames={activeTheme.multiselect.select}
                onChange={(values, _action) => {
                  setSearchParams((prevParams) => ({
                    ...prevParams,
                    metalColor: values.map((item) => item.value),
                  }));
                  setCurrentPage(1);
                }}
                options={
                  searchProperties.metal_color &&
                  searchProperties.metal_color.map((item) => ({
                    label: item,
                    value: item,
                  }))
                }
                placeholder="Metal Color"
                value={searchParams.metalColor.map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
            </div>
            <Accordion
              isFirstTabActive={expandAdvancedSearch(searchParams)}
              theme={activeTheme.accordion}
              data={[
                {
                  label: "Advanced Search",
                  content: (
                    <div className="mb-2 flex flex-col gap-6">
                      <div className="mt-2">
                        <p className="text-sm font-bold">
                          Item Number or Description
                        </p>
                        <input
                          className={`${activeTheme.input} mt-2 w-full text-sm focus:ring-0`}
                          data-previous-value=""
                          name="description"
                          onBlur={(e) => {
                            handleParamDescription(e);
                          }}
                          onChange={(e) => {
                            setParamDescription(e.target.value);
                          }}
                          onFocus={(e) => {
                            e.target.setAttribute(
                              "data-previous-value",
                              e.target.value,
                            );
                          }}
                          onKeyDown={(e) => {
                            if (e.code === "Enter") {
                              handleParamDescription(e);
                            }
                          }}
                          placeholder="Search by item number or description"
                          type="text"
                          value={paramDescription}
                        />
                      </div>
                      <PickItems
                        title="Metal Karat"
                        options={Jewelry.MetalKarats()}
                        theme={{
                          gridClass: "grid-cols-4",
                          textClass: "text-2xs sm:text-3xs lg:text-2xs",
                        }}
                        selectedValues={searchParams.metalKarat}
                        callback={(value) => {
                          const newMetalKaratsValues = _.includes(
                            searchParams.metalKarat,
                            value,
                          )
                            ? _.without(searchParams.metalKarat, value)
                            : [...searchParams.metalKarat, value];

                          setSearchParams((prevParams) => ({
                            ...prevParams,
                            metalKarat: newMetalKaratsValues,
                          }));
                          setCurrentPage(1);
                        }}
                      />
                      <PickItems
                        title="Size"
                        options={Jewelry.Sizes()}
                        theme={{
                          gridClass: "grid-cols-6",
                          textClass: "text-2xs sm:text-3xs lg:text-2xs",
                        }}
                        selectedValues={searchParams.size}
                        callback={(value) => {
                          const newSizeValues = _.includes(
                            searchParams.size,
                            value,
                          )
                            ? _.without(searchParams.size, value)
                            : [...searchParams.size, value];

                          setSearchParams((prevParams) => ({
                            ...prevParams,
                            size: newSizeValues,
                          }));
                          setCurrentPage(1);
                        }}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </aside>
          <main className="flex-1 overflow-x-auto">
            {paginationAndViewSwitcher}
            {viewType === "grid" && (
              <div className="my-4">
                <ItemGrid data={results} />
              </div>
            )}
            {viewType === "table" && (
              <Table
                data={results}
                columns={tableColumns().filter(
                  (column) => column.key !== "data.category",
                )}
                detailSettings={detailSettings}
                quoteType={isClientUser(userRole) && "jewelry"}
                enableSorting={true}
                showBookmarks={true}
              />
            )}
            {paginationAndViewSwitcher}
          </main>
        </div>
      ),
    },
    {
      label: `Compare (${comparisonData.length})`,
      content: (
        <Compare
          columns={tableColumns().filter(
            (column) => column.key !== "categoryDetails",
          )}
          theme={activeTheme.compare}
        />
      ),
    },
  ];

  return <Tabs data={tabData} />;
}
