// assets
import BannerDiamonds from "../../assets/images/banners/diamonds-on-hand.webp";
import BannerJewelry from "../../assets/images/banners/jewelry-item-variety.jpg";

export default function OrderDetails({ data }) {
  const orderDetailsImage =
    data.type === "diamond" ? BannerDiamonds : BannerJewelry;

  // TODO: this is temporary
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 3);
  const deliveryDate = currentDate.toLocaleString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });

  return (
    <div className="flex flex-col items-stretch font-semibold text-white bg-cartier-red md:mt-4 md:flex-row">
      <div className="md:w-1/2">
        <div className="p-2 md:p-4 lg:p-12">
          <h3 className="mb-1 font-bold">All Order Include</h3>
          <ul className="space-y-1 list-disc list-inside">
            <li>Lab Certificate</li>
            <li>Professional Appraisal</li>
            <li>Free Lifetime Warranty</li>
          </ul>
          <h3 className="mt-4 mb-1 font-bold">Flexible Payment Options</h3>
          <ul className="space-y-1 list-disc list-inside">
            <li>Special 12-month, 0% APR financing available</li>
            <li>Easy 36 monthly payments available, subject to approval</li>
            <li>Layaway options also available</li>
          </ul>
          <h3 className="mt-4 mb-1 font-bold">
            Estimated Delivery:{" "}
            <span className="underline">{deliveryDate}</span>
          </h3>
          <ul className="space-y-1 list-disc list-inside">
            <li>Estimated delivery if ordered by 2PM PST today</li>
            <li>Free worldwide FedEx shipping</li>
            <li>Free 30 day returns</li>
            <li>100% insured</li>
          </ul>
        </div>
      </div>
      <div className="flex-1 hidden md:flex md:w-1/2">
        <img
          alt=""
          className="object-cover object-left h-full"
          src={orderDetailsImage}
        />
      </div>
    </div>
  );
}
