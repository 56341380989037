export const faq = {
  "What Kind Of Inventory Do You Have?":
    "We maintain a collection of thousands of loose diamonds in all shapes, ranging in sizes from 0.50 carats to 10 carats.",
  "Are Your Diamonds Certified?":
    "Yes, all diamonds in our inventory are certified through either GIA or EGL USA.",
  "How Do I See The Prices For Your Inventory?":
    "We offer free overnight shipping on all memo orders. You would only have to pay if you don't sell a diamond or need to return or exchange an item.",
  "How Does Shipping Work?":
    "Because we are a diamond wholesaler, our prices are not available to the general public. If you are a jeweler or buyer in the industry, simply call us to set up an account. After approval, you will have access to our pricing.",
  "How Many Days Can I Keep A Diamond":
    "Our standard memo term is seven (7) days, beginning the day you receive a diamond on memo. If you need more time, just let us know and we are happy to accommodate you.",
};
