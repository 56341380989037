import { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";

import Header from "../components/Header";
import LoadingIndicator from "../shared/components/LoadingIndicator";
import Pagination from "../shared/components/Pagination";
import StandardContainer from "../components/StandardContainer";
import Table from "../shared/components/Table";

export default function Users() {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchRequested, setSearchRequested] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (searchRequested) {
      fetchData();
      setSearchRequested(false);
    }
  }, [currentPage, searchRequested]);

  const fetchData = () => {
    setLoading(true);
    const params = {
      page: currentPage,
      search: searchQuery,
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/users.json`, {
        headers: {
          Authorization: localStorage.token,
        },
        params: params,
      })
      .then((response) => {
        setData(
          response.data.data.map((item) => {
            return {
              ...item,
              email: item.email && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<a class="text-theme-blue" href="mailto:${item.email}">${item.email}</a>`,
                  }}
                />
              ),
              role: _.capitalize(item.role),
              deactivated: (
                <button
                  onClick={() => toggleUserStatus(item.id, item.deactivated)}
                  className={`rounded-md bg-theme-${item.deactivated ? "red" : "blue"} px-4 py-2 text-white`}
                >
                  {item.deactivated ? "Activate" : "Deactivate"}
                </button>
              ),
            };
          }),
        );
        setTotalPages(response.data.meta.page_count);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleUserStatus = (id, isDeactivated) => {
    const confirmationMessage = isDeactivated
      ? "Are you sure you want to activate this user?"
      : "Are you sure you want to deactivate this user?";

    if (confirm(confirmationMessage)) {
      const endpoint = isDeactivated ? "activate" : "deactivate";

      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/${endpoint}/${id}`,
          {},
          {
            headers: {
              Authorization: localStorage.token,
            },
          },
        )
        .then((response) => {
          const action = isDeactivated ? "Activated" : "Deactivated";
          console.log(`${action}:`, response.data);
          fetchData();
        })
        .catch((error) => {
          const action = isDeactivated ? "activating" : "deactivating";
          console.error(`Error ${action} user:`, error);
        });
    }
  };

  const handlePageChange = ({ selected }) => {
    setSearchRequested(true);
    setCurrentPage(selected + 1);
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleReset = () => {
    setCurrentPage(1);
    setSearchQuery("");
    setSearchRequested(true);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setSearchRequested(true);
  };

  const tableColumns = [
    { key: "full_name", label: "Name", link: true, path: "/users" },
    { key: "email", label: "Email" },
    { key: "role", label: "Role", classNames: "text-right" },
    {
      key: "deactivated",
      label: "Status",
      classNames: "text-right",
      ignoreRowClick: true,
    },
  ];

  const content = loading ? (
    <LoadingIndicator message="Loading users" />
  ) : (
    <>
      <div className="my-2 flex gap-2 max-md:flex-col">
        <input
          className="rounded-md md:w-4/6"
          name="search"
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
          placeholder="Search by name or email"
          type="text"
          value={searchQuery}
        />
        <button
          className="rounded-md bg-theme-blue px-4 py-2 text-white md:w-1/6"
          onClick={handleSearch}
        >
          Search
        </button>
        <button
          className="rounded-md bg-theme-red px-4 py-2 text-white md:w-1/6"
          onClick={handleReset}
        >
          Reset
        </button>
      </div>
      <Pagination
        currentPage={currentPage - 1}
        onPageChange={handlePageChange}
        pageCount={totalPages}
      />
      <Table data={data} columns={tableColumns} />
      <Pagination
        currentPage={currentPage - 1}
        onPageChange={handlePageChange}
        pageCount={totalPages}
      />
    </>
  );

  return (
    <>
      <Header title="Users" />
      <StandardContainer content={content} />
    </>
  );
}
