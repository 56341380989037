// packages
import { useState, useEffect } from "react";
import axios from "axios";

// classes
import Diamond from "../../shared/classes/Diamond";
import Jewelry from "../../shared/classes/Jewelry";

// components (shared)
import ItemGrid from "../../shared/components/ItemGrid";

// TODO: replace with similar items when implemented instead of featured items
export default function SimilarItems({ type }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/companies/${process.env.REACT_APP_COMPANY_ID}/featured-items.json`,
        {
          headers: {
            Authorization: localStorage.token,
          },
        },
      )
      .then((response) => {
        if (type === "diamond") {
          setData(
            response.data.diamonds.map((item) => {
              return new Diamond(item, "/diamonds");
            }),
          );
        } else if (type === "jewelry") {
          setData(
            response.data.jewelry.map((item) => {
              return new Jewelry(item, "/jewelry");
            }),
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching similar items:", error);
      });
  }, []);

  return (
    data && (
      <div className="my-4">
        <h3 className="mb-4 text-center text-xl font-semibold leading-snug">
          Similar Items
        </h3>
        <ItemGrid data={data} limit={4} />
      </div>
    )
  );
}
