// packages
import axios from "axios";
import "flowbite";
import { useEffect, useState } from "react";
import {
  PiChatLight,
  PiCurrencyCircleDollarLight,
  PiEnvelopeSimpleLight,
  PiHeartLight,
  PiListLight,
  PiPhoneLight,
  PiSignOutLight,
  PiUserCircleLight,
  PiUserLight,
} from "react-icons/pi";
import { NavLink, useNavigate } from "react-router-dom";

// assets
import Logo from "../assets/logos/logo-dark.avif";

// data
import { meta } from "../data/meta";
import { navbar } from "../data/nav";

// utils
import { isTokenValid } from "../shared/utils/auth";

const PROFILE_KEY = "profile";
const TOKEN_KEY = "token";

export default function Navbar() {
  //////////////////////////////////////////////////////////////////////////////
  // dropdown & navigation
  //////////////////////////////////////////////////////////////////////////////

  const [openMainMenu, setOpenMainMenu] = useState(false);
  const navigate = useNavigate();

  //////////////////////////////////////////////////////////////////////////////
  // dropdown offset & skidding, based on viewport size
  //////////////////////////////////////////////////////////////////////////////

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [dropdownOffsetDistance, setDropdownOffsetDistance] = useState(0);
  const [dropdownOffsetSkidding, setDropdownOffsetSkidding] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth <= 320) {
      setDropdownOffsetDistance(8);
      // setDropdownOffsetDistance(9);
      // setDropdownOffsetSkidding(-42);
    } else if (screenWidth <= 440) {
      setDropdownOffsetDistance(8);
      // setDropdownOffsetDistance(17);
      // setDropdownOffsetSkidding(-33);
    } else if (screenWidth <= 640) {
      setDropdownOffsetDistance(16);
      // setDropdownOffsetDistance(17);
      // setDropdownOffsetSkidding(-33);
    } else if (screenWidth <= 768) {
      setDropdownOffsetDistance(16);
      // setDropdownOffsetDistance(17);
      // setDropdownOffsetSkidding(-33);
    } else if (screenWidth <= 1024) {
      setDropdownOffsetDistance(16);
      // setDropdownOffsetDistance(17);
      // setDropdownOffsetSkidding(-33);
    } else {
      setDropdownOffsetDistance(16);
      setDropdownOffsetSkidding(-1);
      // setDropdownOffsetDistance(18);
      // setDropdownOffsetSkidding(-35);
    }
  }, [screenWidth]);

  //////////////////////////////////////////////////////////////////////////////
  // user
  //////////////////////////////////////////////////////////////////////////////

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    if (!isTokenValid()) {
      localStorage.removeItem(PROFILE_KEY);
    } else {
      setIsAuthenticated(true);

      if (localStorage.getItem(PROFILE_KEY)) {
        setProfile(JSON.parse(localStorage.getItem(PROFILE_KEY)));
      }
    }
  }, [localStorage.getItem(TOKEN_KEY)]);

  const handleLogout = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/logout`, {
        headers: {
          Authorization: localStorage.getItem(TOKEN_KEY),
        },
      });

      localStorage.removeItem(PROFILE_KEY);
      localStorage.removeItem(TOKEN_KEY);
      setProfile({});
      setIsAuthenticated(false);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
      // alert("Error signing out, please try again.");
    }
  };

  const userNavData = {
    signedIn: [
      {
        href: "/profile",
        text: "Profile",
        icon: <PiUserLight className="shrink-0" />,
      },
      {
        href: "/quotes",
        text: "Quotes",
        icon: <PiCurrencyCircleDollarLight className="shrink-0" />,
      },
      {
        href: "/bookmarks",
        text: "Favorites",
        icon: <PiHeartLight className="shrink-0" />,
      },
      {
        href: "#",
        text: "Sign Out",
        icon: <PiSignOutLight className="shrink-0" />,
        onClick: (e) => handleLogout(e),
      },
    ],
    signedOut: [
      {
        href: "/sign-in",
        text: "Sign In",
      },
      // {
      //   href: "/sign-up",
      //   text: "Sign Up",
      // },
    ],
  };

  const renderUserNav = () => {
    const userNav = isAuthenticated
      ? userNavData.signedIn
      : userNavData.signedOut;

    return (
      <ul
        className="flex flex-col gap-2.5"
        aria-labelledby="user-menu-button"
      >
        {userNav.map((item, idx) => (
          <li key={idx}>
            <NavLink
              {...(item.onClick ? { onClick: item.onClick } : {})}
              className="flex flex-row items-center gap-1.5 text-t-skin hover:text-t-beige"
              to={item.href}
            >
              {item.icon}
              {item.text}
            </NavLink>
          </li>
        ))}
      </ul>
    );
  };

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const bannerSection = (
    <div className="mx-auto flex flex-row justify-between gap-4 bg-t-black p-2 text-sm sm:p-4">
      <div className="flex flex-row gap-4 xl:gap-8">
        <a
          href={`tel:${meta.phone}`}
          className="flex flex-row items-center gap-1 text-t-skin hover:text-t-beige sm:gap-2"
        >
          <PiPhoneLight
            className="shrink-0"
            size={16}
          />
          Call
        </a>
        <a
          href={meta.chat}
          className="flex flex-row items-center gap-1 text-t-skin hover:text-t-beige sm:gap-2"
        >
          <PiChatLight
            className="shrink-0"
            size={18}
          />
          Chat
        </a>
      </div>
      <div className="flex flex-row gap-4 xl:gap-8">
        <NavLink
          to="/contact"
          className="flex flex-row items-center gap-1 text-t-skin hover:text-t-beige sm:gap-2"
        >
          <PiEnvelopeSimpleLight
            className="shrink-0"
            size={18}
          />
          Contact
        </NavLink>
        {!isAuthenticated ? (
          <NavLink
            to="/sign-in"
            className="flex flex-row items-center gap-1 text-t-skin hover:text-t-beige sm:gap-2"
          >
            <PiUserCircleLight
              className="shrink-0"
              size={18}
            />
            Sign In
          </NavLink>
        ) : (
          <button
            type="button"
            aria-expanded="false"
            className="flex flex-row items-center gap-1 text-t-skin hover:text-t-beige sm:gap-2"
            data-dropdown-placement="bottom"
            data-dropdown-toggle="user-menu"
            data-dropdown-offset-distance={dropdownOffsetDistance}
            data-dropdown-offset-skidding={dropdownOffsetSkidding}
            id="user-menu-button"
          >
            <PiUserCircleLight
              className="shrink-0"
              size={18}
            />
            Account
          </button>
        )}
      </div>
      {isAuthenticated && (
        <div
          className="z-50 hidden border-r border-t border-white bg-t-black px-4 py-3"
          id="user-menu"
        >
          {renderUserNav()}
        </div>
      )}
    </div>
  );

  const logoSection = (
    <NavLink to="/">
      <img
        className="h-12 object-contain sm:h-16 md:h-auto md:max-w-96 md:pb-6 md:pt-8"
        src={Logo}
        alt={meta.title}
      />
    </NavLink>
  );

  const buttonSection = (
    <button
      className="text-t-gray md:hidden"
      onClick={() => setOpenMainMenu(!openMainMenu)}
    >
      <PiListLight
        className="shrink-0"
        size={32}
      />
    </button>
  );

  const navLinks = (
    <ul
      className={`${openMainMenu ? "visible" : "max-md:hidden"} flex flex-col gap-1 p-2 max-md:bg-t-black sm:p-4 md:flex-row md:justify-center md:gap-8 md:border-y md:border-t-skin`}
    >
      {Object.keys(navbar).map((link, idx) => {
        return (
          <li key={idx}>
            <NavLink
              className={({ isActive }) =>
                `s-h4 uppercase tracking-wider text-t-skin transition-colors duration-500 hover:text-black md:text-gray-500 ${isActive && "font-semibold"}`
              }
              onClick={() => {
                if (window.innerWidth <= 768) {
                  setOpenMainMenu(!openMainMenu);
                }
              }}
              to={navbar[link].href}
            >
              {navbar[link].text}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );

  return (
    <>
      {bannerSection}
      <div className="flex flex-row items-center justify-between gap-4 py-2 pr-2 sm:py-4 sm:pr-4 md:justify-center md:p-0">
        {logoSection}
        {buttonSection}
      </div>
      {navLinks}
    </>
  );
}
