// packages
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// assets
import "swiper/css";

// components
import StandardContainer from "./StandardContainer";

// data
import { slider } from "../data/components/slider";

export default function Slider() {
  const content = (
    <div>
      <Swiper
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          648: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
            slidesPerGroup: 3,
          },
        }}
        autoplay={{
          delay: 2500,
        }}
        speed={1500}
        loop={true}
        modules={[Autoplay]}
      >
        {slider.map((item, idx) => (
          <SwiperSlide key={idx}>
            <img
              src={item.image.src}
              alt=""
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );

  return <StandardContainer content={content} />;
}
