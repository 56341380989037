// assets
import {
  PiFacebookLogoBold,
  PiInstagramLogoBold,
  PiLinkedinLogoBold,
} from "react-icons/pi";

export const navbar = [
  {
    href: "/diamonds",
    text: "Diamonds",
  },
  {
    href: "/jewelry",
    text: "Jewelry",
  },
  {
    href: "/catalog",
    text: "Catalog",
  },
  // TODO: once we have the content
  // {
  //   href: "#",
  //   text: "Marketing",
  // },
  {
    href: "/about",
    text: "About",
  },
  {
    href: "/contact",
    text: "Contact",
  },
];

export const footer = {
  Collections: {
    Diamonds: "/diamonds",
    Jewelry: "/jewelry",
    Catalog: "/catalog",
  },
  Company: {
    "About": "/about",
    "Shipping": "#",
    "Return or Exchange": "#",
  },
  Contact: {
    Call: "tel:+12136244400",
    Chat: "https://wa.me/12136244400",
    Contact: "/contact",
  },
};

export const appendix = {
  "Terms of Service": "/terms",
  "Privacy Policy": "/privacy",
};

export const addresses = [
  {
    line1: "550 S Hill St #1101",
    line2: "Los Angeles, CA 90013",
  },
  {
    line1: "580 5th Ave #206",
    line2: "New York, NY 10036",
  },
];

export const social = {
  linkedin: {
    icon: <PiLinkedinLogoBold />,
    url: "https://www.linkedin.com/company/ninacci-inc.",
  },
  facebook: {
    icon: <PiFacebookLogoBold />,
    url: "https://www.facebook.com/Ninacci/",
  },
  instagram: {
    icon: <PiInstagramLogoBold />,
    url: "https://www.instagram.com/ninacci_diamonds/",
  },
};
