// packages
import _ from "lodash";
import { loremIpsum } from "lorem-ipsum";
import { MdOutlineStar, MdOutlineStarHalf } from "react-icons/md";

export default function JewelryReviews() {
  return (
    <div className="hidden border-t border-gray-200 md:block">
      <h3 className="my-4 border-none text-center text-xl font-semibold">
        Reviews
      </h3>
      <div className="grid gap-2 md:gap-4 lg:grid-cols-2">
        {Array.from({ length: 2 }).map((_item, index) => (
          <div className="border border-gray-200 p-4" key={index}>
            <h4 className="flex justify-between border-none">
              <div>
                {_.startCase(
                  loremIpsum({
                    count: _.random(2, 4),
                    units: "words",
                  }),
                )}
              </div>
              <div className="flex">
                {Array.from({ length: _.random(3, 4) }).map((_item, index) => (
                  <MdOutlineStar
                    className="shrink-0 text-cartier-red"
                    key={index}
                    size={16}
                  />
                ))}
                {!!_.random(0, 1) && (
                  <MdOutlineStarHalf
                    className="shrink-0 text-cartier-red"
                    size={16}
                  />
                )}
              </div>
            </h4>
            <p className="mt-2 leading-snug">
              {loremIpsum({
                count: 3,
                sentenceLowerBound: 6,
                sentenceUpperBound: 8,
              })}
            </p>
            <p className="mt-2 text-right font-semibold italic">
              &mdash;&nbsp;
              {_.startCase(
                loremIpsum({
                  count: _.random(1, 2),
                  units: "words",
                }),
              )}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
