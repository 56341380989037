export const process = {
  "Text Us Your Memo Order": [
    "One text is all it takes. Everything in our inventory can be provided to you on memo. Just scan our inventory list, and then send a text or WhatsApp of what you need to: (213) 810-7333. We usually respond within minutes and will reply with a memo confirming your order.",
    "As soon as your order is placed, the diamonds are taken offline (removed from RapNet and Polygon, etc.) so your cost and sources remain private.",
  ],
  "We Ship To You For Free": [
    "After we've confirmed your order, we immediately prepare it for shipping. We send it to you for free with overnight shipping via FedEx AM delivery, so it gets there fast and will arrive at your office the next morning.",
  ],
  "Purchase What Works, Send Back What Doesn't": [
    "Once your order arrives, you've got seven (7) days to decide whether or not you'd like to purchase. If you need a few more days to decide on any items, just let us know. After the memo period, you're free to purchase what works and send back what doesn't.",
    "We offer full refunds for 30 days after payment so you can be confident in all your purchases with us. We also protect you for life. If you decide that you'd like to either trade up or trade down a diamond, we'll give you credit for the purchase price to exchange for something else in our inventory. We'll let you do this for life.",
  ],
};
