// assets
import ImageBeverlyHills from "../assets/images/cities/beverly-hills.png";
import ImageBoston from "../assets/images/cities/boston.png";
import ImageLasVegas from "../assets/images/cities/las-vegas.png";
import ImageMiami from "../assets/images/cities/miami.png";
import ImageNewYork from "../assets/images/cities/new-york.png";
import ImageSanFrancisco from "../assets/images/cities/san-francisco.png";

export const locations = [
  {
    image: ImageBeverlyHills,
    address: "123 North Main Street",
    city: "Beverly Hills",
    state: "CA",
    zip: "90210",
    phone: "(310) 123-4567",
    email: "bh@DJcompany.com",
    hours: {
      Monday: "Closed",
      Tuesday: "11 AM - 5 PM",
      Wednesday: "11 AM - 5 PM",
      Thursday: "11 AM - 5 PM",
      Friday: "10 AM - 6 PM",
      Saturday: "9 AM - 8 PM",
      Sunday: "9 AM - 7 PM",
    },
  },
  {
    image: ImageSanFrancisco,
    address: "415 West Embarcadero Way",
    city: "San Francisco",
    state: "CA",
    zip: "94105",
    phone: "(415) 321-7654",
    email: "sf@DJcompany.com",
    hours: {
      Monday: "Closed",
      Tuesday: "11 AM - 5 PM",
      Wednesday: "11 AM - 5 PM",
      Thursday: "11 AM - 5 PM",
      Friday: "10 AM - 6 PM",
      Saturday: "9 AM - 8 PM",
      Sunday: "9 AM - 7 PM",
    },
  },
  {
    image: ImageLasVegas,
    address: "702 South Vegas Boulevard",
    city: "Las Vegas",
    state: "NV",
    zip: "89109",
    phone: "(702) 654-7890",
    email: "lv@DJcompany.com",
    hours: {
      Monday: "Closed",
      Tuesday: "11 AM - 5 PM",
      Wednesday: "11 AM - 5 PM",
      Thursday: "11 AM - 5 PM",
      Friday: "10 AM - 6 PM",
      Saturday: "9 AM - 8 PM",
      Sunday: "9 AM - 7 PM",
    },
  },
  {
    image: ImageNewYork,
    address: "212 East Fifth Avenue",
    city: "New York",
    state: "NY",
    zip: "10036",
    phone: "(212) 789-0123",
    email: "ny@DJcompany.com",
    hours: {
      Monday: "Closed",
      Tuesday: "11 AM - 5 PM",
      Wednesday: "11 AM - 5 PM",
      Thursday: "11 AM - 5 PM",
      Friday: "10 AM - 6 PM",
      Saturday: "9 AM - 8 PM",
      Sunday: "9 AM - 7 PM",
    },
  },
  {
    image: ImageBoston,
    address: "617 SW Independence Pass",
    city: "Boston",
    state: "MA",
    zip: "02113",
    phone: "(617) 987-6543",
    email: "boston@DJcompany.com",
    hours: {
      Monday: "Closed",
      Tuesday: "11 AM - 5 PM",
      Wednesday: "11 AM - 5 PM",
      Thursday: "11 AM - 5 PM",
      Friday: "10 AM - 6 PM",
      Saturday: "9 AM - 8 PM",
      Sunday: "9 AM - 7 PM",
    },
  },
  {
    image: ImageMiami,
    address: "305 NE Palm Beach",
    city: "Miami",
    state: "FL",
    zip: "33130",
    phone: "(305) 123-4567",
    email: "miami@DJcompany.com",
    hours: {
      Monday: "Closed",
      Tuesday: "11 AM - 5 PM",
      Wednesday: "11 AM - 5 PM",
      Thursday: "11 AM - 5 PM",
      Friday: "10 AM - 6 PM",
      Saturday: "9 AM - 8 PM",
      Sunday: "9 AM - 7 PM",
    },
  },
];
