// assets
import BannerImage from "../assets/images/banners/gift-box.webp";

// components (template)
// import Header from "../components/Header";
// import ImageBanner from "../components/ImageBanner";
import Hero from "../components/Hero";
import StandardContainer from "../components/StandardContainer";

// data
import { contacts } from "../data/contacts";

export default function Contact() {
  const content = (
    <div className="my-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:my-8 lg:grid-cols-3 lg:gap-8">
      {contacts.map((contact, index) => {
        return (
          <div key={index}>
            <h2 className="uppercase">{contact.title}</h2>
            <h3 className="mb-1 mt-2 text-lg">{contact.subtitle}</h3>
            {contact.content && (
              <ul className="flex flex-col space-y-1">
                {contact.content.map((item, index2) => {
                  return <li key={index2}>{item}</li>;
                })}
              </ul>
            )}
            <a
              href={contact.link.href}
              className="text-lg"
              dangerouslySetInnerHTML={{ __html: contact.link.text }}
            ></a>
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      <Hero
        title="Contact"
        image={BannerImage}
        content={[
          "Our associates are standing by to answer any queries promptly and are ready to guide you through the process of buying fine diamond jewelry.",
          "You may also send us general comments about your shopping experience. We always appreciate your feedback, so we encourage you to call, fax, or email.",
        ]}
      />
      <StandardContainer content={content} />
    </>
  );
}
